import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, sip, menu, did} from "../translate";
import {useDispatch} from "react-redux";
import {ipCreateRequest, ipLocal} from "../store/actions/ip";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 750 ? 750 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerWidth > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function AddIp({open, setOpen}) {
    const [form, setForm] = useState({
        ip: '',
        type:1
    })
    const dispatch = useDispatch()
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(ipCreateRequest(form))
        setOpen(false)
    }, [form])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.ip[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                   <form onSubmit={handleAdd}>
                       <div className="row" style={{margin: '30px 0'}}>
                           <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               {global.ip[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                               <input value={form.ip} onChange={(ev) => handleChange('ip', ev.target.value)}/>
                           </label>
                           <label className="col-md-12 count" style={{margin: '15px 0 5px'}}>
                                    <span className="d-flex align-items-center">
                                        {global.api_ip[+localStorage.getItem('atsLang') || 1]}:
                                    <input style={{marginLeft: 20}}
                                           className="w-auto"
                                           type="checkbox"
                                           checked={form.type === 2}
                                           onChange={(ev) => handleChange('ord', ev.target.checked ? 2 : 1)}/>
                                    </span>

                           </label>
                       </div>
                       <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                           <button onClick={handleAdd} className="addBtnBig">
                               {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                           </button>
                       </div>
                   </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddIp;
