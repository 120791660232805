export const FORWARD_REQUEST = 'FORWARD_REQUEST';
export const FORWARD_SUCCESS = 'FORWARD_SUCCESS';
export const FORWARD_FAIL = 'FORWARD_FAIL';

export function forwardRequest(limit,page,search,sortBy,sortOrder) {
  return {
    type: FORWARD_REQUEST,
    payload: { limit,page,search,sortBy,sortOrder },
  };
}

export const FORWARD_CREATE_REQUEST = 'FORWARD_CREATE_REQUEST';
export const FORWARD_CREATE_SUCCESS = 'FORWARD_CREATE_SUCCESS';
export const FORWARD_CREATE_FAIL = 'FORWARD_CREATE_FAIL';

export function forwardCreateRequest(limit,page,search,form) {
  return {
    type: FORWARD_CREATE_REQUEST,
    payload: { limit,page,search,form },
  };
}

export const FORWARD_EDIT_REQUEST = 'FORWARD_EDIT_REQUEST';
export const FORWARD_EDIT_SUCCESS = 'FORWARD_EDIT_SUCCESS';
export const FORWARD_EDIT_FAIL = 'FORWARD_EDIT_FAIL';

export function forwardEditRequest(limit,page,search,form) {
  return {
    type: FORWARD_EDIT_REQUEST,
    payload: { limit,page,search,form },
  };
}
export const FORWARD_ADD_LOCAL = 'FORWARD_ADD_LOCAL';

export function forwardLocal(data) {
  return {
    type: FORWARD_ADD_LOCAL,
    payload: { data },
  };
}
export const FORWARD_DELETE_LOCAL = 'FORWARD_DELETE_LOCAL';

export function forwardDeleteLocal(id) {
  return {
    type: FORWARD_DELETE_LOCAL,
    payload: { id },
  };
}



