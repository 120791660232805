import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang, short, sip, weekDays} from "../translate";
import {useDispatch} from "react-redux";
import {shortNumberEdit} from "../store/actions/shortNumber";
import Api from "../Api";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import 'flatpickr/dist/themes/material_blue.css';
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {closestCorners, DndContext} from "@dnd-kit/core";
import IvrLine from "./IvrLine";
import ShortLine from "./ShortLine";
import {toast} from "react-toastify";
import CreatableSelect from "react-select/creatable";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '97vh'
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};

function EditShort({open, setOpen}) {
    const [form, setForm] = useState({
        name: '',
        virtual: '',
        noaction: '',
        sip: [],
        wait: 0,
        sec: 0
    })

    const [sipData, setSip] = useState([])
    const [loading, setLoading] = useState(true)

    const [graphic, setGraphic] = useState({
        id: new Date(),
        num: '',
        notes: '',
        weekStart: weekDays[1][+localStorage.getItem('atsLang') || 1],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang') || 1],
        timeStart: '00:00',
        timeEnd: '23:59',
    })


    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        if (name === 'sec') {
            if (+ev > 60) {
                setForm((prevState) => ({
                    ...prevState,
                    sec: 60
                }))
            } else {
                setForm((prevState) => ({
                    ...prevState,
                    sec: ev
                }))
            }
        } else {
            if(name === 'noaction' && !parseInt(ev) && ev?.length){
                toast.error('Need to be number')
                return
            }
            setForm((prevState) => ({
                ...prevState,
                [name]: ev
            }))
        }

    }, [])
    const handleTimeChange = useCallback((name, ev, notes) => {
        if (name === 'num') {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
                notes: notes || ''
            }))
        } else {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
            }))
        }
    }, [])
    const [list,setList] = useState([])

    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(shortNumberEdit(Cookies.get("short") ||query.limit || 10, query.page || 1, query.search || '', open, form, query.sortBy || '', query.sortOrder || ''))
        setOpen(false)
    }, [form, open, location.search])
    const handleAddGraphic = useCallback(async () => {
        if(!graphic.num){
            toast.error('Enter number value')
            return
        }
        setForm((prev) => ({
            ...prev,
            sip: [...prev.sip, {...graphic, id: new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        try {
            setForm((prev) => ({
                ...prev,
                sip: prev.sip.filter(t => t.id !== id)
            }))
        } catch (e) {

        }
    }, [graphic])
    useEffect(() => {
        (async () => {
            try {

                const {data} = await Api.shortSingle(open)
                const sipArr = await Api.shortSip()
                setSip(sipArr.data.sip)
                setForm({
                    name: data.single.name,
                    virtual: data.single.virtual,
                    noaction: data.single.noaction,
                    sip: data.single.item.map(i => ({
                        id: i.id,
                        num: i.buddy,
                        notes: i.memeber_buddy[0]?.notes,
                        weekStart: weekDays[+i.days.split('-')[0] - 1][+localStorage.getItem('atsLang') || 1],
                        weekEnd: weekDays[+i.days.split('-')[1] - 1][+localStorage.getItem('atsLang') || 1],
                        timeStart: i.hours.split('-')[0],
                        timeEnd: i.hours.split('-')[1],
                    })),
                    wait: data.single.cwaiting,
                    sec: data.single.timeout
                })
                setLoading(false)
            } catch (e) {
                console.log(e)
            }
            const {data} = await Api.shortFunctionGetList()
            setList(data.data)
        })()
    }, [open])
    const getShortPos = (id) => form.sip.findIndex((ivr) => ivr.id === id);
    const handleTime = (name,event) => {
        const input = event.target.value;
        if (/^([0-9]{0,2}):?([0-9]{0,2})?$/.test(input) || input === '') {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: input,
            }))
        }
    };

    const handleBlur = (name) => {
        if (graphic[name]) {
            const [hours, minutes] = graphic[name].split(':').map(Number);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            setGraphic((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }
    };
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id === over.id) return;
        setForm((tasks) => {
            const originalPos = getShortPos(active.id);
            const newPos = getShortPos(over.id);
            return {
                ...form,
                sip:  arrayMove(tasks.sip, originalPos, newPos)
            };

        });
    };

    if(loading){
        return null
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {short.short[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                       <CloseIcon/>

                        </span>
                    </div>
                    <form onSubmit={handleAdd}>
                        <div className="row" style={{margin: '30px 0'}}>
                            <label style={{margin: '20px 0'}} className="col-md-9 count">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input value={form.name} onChange={(ev) => handleChange('name', ev.target.value)}/>
                            </label>
                            <label className="col-md-3 count " style={{margin: '20px 0'}}>
                                <span
                                    className="d-flex flex-column align-items-start justify-content-between" style={{
                                    margin: 0,
                                    height: '100%'
                                }}>{short.wait[+localStorage.getItem('atsLang') || 1]}<input
                                    style={{width: 20}} checked={form.wait}
                                    onChange={(ev) => handleChange('wait', ev.target.checked ? 1 : 0)} type="checkbox"/></span>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-9 count">
                            <span>
                               {short.virtual[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input value={form.virtual}
                                       onChange={(ev) => handleChange('virtual', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-3 count">
                            <span>
                               {short.sec[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input value={form.sec}
                                       onChange={(ev) => handleChange('sec', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                            <span>
                               {short.no_answer[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <CreatableSelect
                                    options={list.map(l =>({value:l.code,label:l[lang[+localStorage.getItem('atsLang')]]}))}
                                    onChange={(ev) => handleChange('noaction', ev?.value)}
                                    isClearable
                                    value={form.noaction ? { value: form.noaction, label: list.find(l => l.code === form.noaction)?list.find(l => l.code === form.noaction)[lang[+localStorage.getItem('atsLang')]]:form.noaction } : null}
                                />
                                {/*<input list="options" value={form.noaction} onChange={(ev) => handleChange('noaction', ev.target.value)}/>*/}
                                {/*<datalist id="options">*/}
                                {/*    {list.map(l => (*/}
                                {/*        <option key={l.title} value={l.title}/>*/}
                                {/*    ))}*/}
                                {/*</datalist>*/}
                            </label>
                        </div>
                        <div className="short_block" style={{margin: '30px 0',}}>
                            <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                                <div style={{width: 170}}>
                                    {short.private[+localStorage.getItem('atsLang') || 1]}

                                </div>
                                <div style={{width: 150}}>
                                    {short.week[+localStorage.getItem('atsLang') || 1]}
                                </div>
                                <div style={{width: 150}}>
                                    {short.time[+localStorage.getItem('atsLang') || 1]}
                                </div>
                                <div style={{width: 150}}>
                                    {sip.action[+localStorage.getItem('atsLang') || 1]}
                                </div>
                            </div>

                            <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                <div >
                                    <SortableContext  items={form.sip} strategy={verticalListSortingStrategy}>
                                        {form.sip.map(s => (
                                            <ShortLine sip={sipData} s={s} handleDelete={handleDelete}/>
                                        ))}
                                    </SortableContext>

                                </div>
                            </DndContext>
                            <div className="d-flex flex-row text-center" style={{width: 'fit-content', margin: '10px 0'}}>

                                <div style={{width: 170}}>
                                    <Select
                                        styles={customStyles}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        onChange={(ev) => handleTimeChange('num', ev.value.split('_')[0], ev.value.split('_')[1])}
                                        menuPortalTarget={document.body}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        defaultValue={graphic.num + '_' + graphic?.notes}
                                        name="color"
                                        options={sipData.map(k => (
                                            {value: k.buddy + '_' + k.notes, label: `${k.buddy}${k.issip ? ' (S)' : ''} ( ${k.notes} )`}
                                        ))}
                                    />
                                </div>
                                <div style={{width: 150}}>
                                    <select value={graphic.weekStart}
                                            onChange={(ev) => handleTimeChange('weekStart', ev.target.value)}
                                            style={{width: '40%', border: '1px solid     border: 1px solid black;',textAlign:'center', height: 35, fontSize: 14}}>
                                        <option>{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k.buddy}
                                                    value={k[+localStorage.getItem('atsLang') || 1]}>{k[+localStorage.getItem('atsLang') || 1]}</option>
                                        ))}
                                    </select>
                                    <span>-</span>
                                    <select value={graphic.weekEnd}
                                            onChange={(ev) => handleTimeChange('weekEnd', ev.target.value)}
                                            style={{width: '40%', border: '1px solid',textAlign:'center', height: 35, fontSize: 14}}>
                                        <option>{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k.buddy}
                                                    value={k[+localStorage.getItem('atsLang') || 1]}>{k[+localStorage.getItem('atsLang') || 1]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{width: 150}}>
                                    <input
                                        type="text"
                                        value={graphic.timeStart}
                                        onChange={(ev) => handleTime('timeStart',ev)}
                                        onBlur={() => handleBlur('timeStart')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />
                                    <span>-</span>
                                    <input
                                        type="text"
                                        value={graphic.timeEnd}
                                        onChange={(ev) => handleTime('timeEnd',ev)}
                                        onBlur={() => handleBlur('timeEnd')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                                <div style={{width: 150}} className="d-flex justify-content-center">
                                    <span onClick={handleAddGraphic} className="addBtn">
                                        {sip.add_btn[+localStorage.getItem('atsLang') || 1]}
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {global.edit[+localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditShort;
