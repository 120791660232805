import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {sipLocalRequest} from "../store/actions/sip";
import {crmCreateRequest, crmSingleRequest} from "../store/actions/crm";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height: '',
    overflow: 'auto'
};

function CreateAndEditCrm({open, buddy, setEdit, setOpen, edit, setCrm}) {
    const [form, setForm] = useState({
        id: open,
        param: ''
    })

    const [params, setParams] = useState({})
    const [url, setUrl] = useState('')
    const [startCall, setStartCall] = useState('')
    const [redirect, setRedirect] = useState(0)
    const [loading, setLoading] = useState(!!edit)

    const crmData = useSelector(state => state.crm.crmData)
    const crmSingle = useSelector(state => state.crm.crmSingleData)
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {

            await dispatch(crmSingleRequest(edit.id))
            setLoading(false)
        })()
    }, [edit])
    const handleChange = useCallback((name, ev) => {
        setParams((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async () => {
        try {
            if (!edit.type) {
                let newForm = {id: open, params, buddy}
                if (redirect && +open === 3) {
                    newForm = {...newForm, params: {...params, 'corporation=': redirect ? 1 : 0}}
                } else if (redirect) {
                    newForm = {...newForm, redirect}
                }
                if (startCall) {
                    newForm = {...newForm, startCall}
                }
                if (url) {
                    newForm = {...newForm, url}
                }
                const {data} = await Api.crmCreate(newForm)
                setCrm(data.crm)
            } else {
                let newForm = {id: open, crm_id: edit.id, params, buddy}
                if (redirect && +open === 3) {
                    newForm = {...newForm, params: {...params, 'corporation=': redirect ? 1 : 0}}
                } else if (redirect) {
                    newForm = {...newForm, redirect}
                }
                if (startCall) {
                    newForm = {...newForm, startCall}
                }
                if (url) {
                    newForm = {...newForm, url}
                }
                const {data} = await Api.crmEdit(newForm)
                setCrm(data.crm)

            }
            setOpen({id: '', buddy: 0})
            setEdit({id: 0, type: ''})
        } catch (e) {

        }
    }, [redirect, open, edit, params, buddy, url, startCall])

    useEffect(() => {
        setParams({})

        try {
            if (edit.type === 'edit' || edit.type === 'view') {
                console.log(crmSingle?.params)

                if (crmSingle?.params?.includes('http') && crmSingle?.crm_list?.id !== 3) {
                    setUrl(crmSingle?.params)
                } else {
                    const pairs = crmSingle?.params?.split('&');
                    const result = {};
                    pairs.forEach(pair => {
                        const parts = pair.split('=');
                        const key = parts[0] + '=';
                        const value = parts[1];
                        if (key === 'corporation=') {
                            setRedirect(1)
                        } else {
                            result[key] = decodeURIComponent(value);
                        }

                    });
                    setParams(result)
                }
                if (crmSingle?.start_call) {
                    setStartCall(crmSingle?.start_call)
                }
                if (+crmSingle?.crm_list?.id !== 3 && +crmSingle?.redirect_call) {
                    setRedirect(1)
                }

                setForm(crmSingle?.crm_list)
            } else {
                setForm(crmData.find(s => +s.id === +open))
                if (crmData.find(s => +s.id === +open)?.example) {
                    setParams(crmData.find(s => +s.id === +open).example.split('&').reduce((acc, currentValue) => {
                        acc[currentValue] = '';
                        return acc;
                    }, {}))
                }
            }

        } catch (e) {

        }
    }, [crmData, edit, crmSingle, open])

    if(loading) return null
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={!!open}
            onClose={() => {
                setOpen({id: '', buddy: 0})
                setEdit({id: 0, type: ''})
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={!!open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {form ? form.name : ''}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setOpen({id: '', buddy: 0})
                            setEdit({id: 0, type: ''})
                        }}>
                            x
                        </span>
                    </div>
                    <div id="transition-modal-description sip_content">

                        <div style={{margin: '20px 0'}}>

                            {form?.example ? form?.example?.split('&').map(p =>
                                <label key={p} className="d-flex flex-column">
                                    <span>{p.split('=')[0]}</span>
                                    <input readOnly={edit.type === 'view'} className="crm_input"
                                           onChange={(ev) => handleChange(p, ev.target.value)} value={params[p]}/>
                                </label>
                            ) : <input readOnly={edit.type === 'view'} className="crm_input w-100" value={url}
                                       onChange={(ev) => setUrl(ev.target.value)}/>}
                            {form?.id === 4 || form?.id === 3 ? <label className="d-flex flex-column">
                                <span>{form?.id === 4 ? 'Token' : 'External call'}</span>
                                <input readOnly={edit.type === 'view'} className="crm_input" value={startCall}
                                       onChange={(ev) => setStartCall(ev.target.value)}/>
                            </label> : null}
                            {form?.id === 99 || form?.id === 3 ? <label className="d-flex flex-row">
                                <span>{form?.id === 3 ? 'Boxed version' : 'Redirect'}</span>
                                <input disabled={edit.type === 'view'} type="checkbox" checked={redirect}
                                       onChange={(ev) => setRedirect(+ev.target.checked)}/>
                            </label> : null}

                        </div>
                        {edit.type === 'view' ? null : <div className="d-flex justify-content-center">
                            <button onClick={handleAdd} className="addBtn">
                                {sip.add_btn[+localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>}
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default CreateAndEditCrm;
