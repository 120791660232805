import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '400px',
    overflow: 'auto'
};

function TestMode({open, setOpen, setData}) {
    const [time, setTime] = useState(new Date().toISOString().slice(0, 10))
    const [check, setCheck] = useState(false)

    const dateInputStartRef = useRef(null);
    const dateInputEndRef = useRef(null);
    useEffect(() => {
        // Initialize the datepicker only if modal is open
        if (open) {
            setTimeout(() => {
                if (dateInputStartRef.current) {
                    // Reinitialize here in case ref was null on initial mount
                    const regionalSettings = lang[+localStorage.getItem('atsLang')] || {};
                    $.datepicker.setDefaults(regionalSettings);

                    $(dateInputStartRef.current).datepicker({
                        dateFormat: 'yy-mm-dd',
                        changeMonth: true,
                        changeYear: true,
                        defaultDate: '+1w',
                        beforeShow: (input, inst) => {
                            // Adjust z-index to be above the modal
                            setTimeout(() => {
                                $(inst.dpDiv).css('z-index', 1350);
                            }, 0);
                        },
                    });

                    $(dateInputStartRef.current).on('change', function () {
                        setTime($(this).val());
                    });
                }
            }, 10); // Small delay ensures DOM stabilization
        }
        return () => {
            // Cleanup to avoid memory leaks or re-initialization
            if (dateInputStartRef.current) {
                $(dateInputStartRef.current).datepicker('destroy');
            }
        };
    }, [open, lang]);

    useEffect(() => {
        setCheck(open.testmode && !open.test_period)
    },[open])

    const handleTest = useCallback(async () => {
       try {
           await Api.test_mode(open.id,check?'unlimit':time,location.pathname)
           setData((prevData) => {
               const updatedData = prevData.groupedArray.map((updatedMember) => {
                   const updatedMembers = updatedMember.members.map((member) => {
                       if (member.id === open.id) {
                           console.log({test_period: check ? null : time})
                           return {
                               ...member,
                               test_period: check ? null : time,
                               testmode: true
                           };
                       }
                       return member;
                   });

                   return {
                       ...updatedMember,
                       members: updatedMembers
                   };
               });

               return {
                   ...prevData,
                   groupedArray: updatedData // Set the updated array in groupedArray
               };
           });

           setOpen(0);
       } catch (e) {
           toast.error(e.response?.data?.message || "Something went wrong");
       }

    }, [open, time,check])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => {
                    setOpen({id: 0})
                }}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open.id}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Test
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen({id: 0})
                            }}>
                                                         <CloseIcon/>

                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row">
                                <div>{open.fio} is Under Test
                                    till: {open.testmode && !open.test_period ? 'Unlimited' : open.test_period || 'Unset'}</div>
                            </div>
                            <div className="d-flex align-items-center flex-column">

                                <div className="d-flex  align-items-start" style={{margin: '10px 0'}}>
                                    <input
                                        ref={dateInputStartRef}
                                        type="text"
                                        value={time}
                                    />
                                </div>
                                <div>or</div>
                                <div><input type="checkbox" onChange={() => setCheck(!check)}
                                            checked={check}/> Unlimited
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button onClick={handleTest} className="addBtn">
                                    {global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default TestMode;
