import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {input, forward, included, sip, global} from "../translate";
import {useDispatch} from "react-redux";
import {didLocal} from "../store/actions/did";
import {forwardCreateRequest, forwardEditRequest, forwardLocal} from "../store/actions/forward";
import Api from "../Api";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function AddAndEditForward({open, edit, setOpen}) {
    const [form, setForm] = useState({
        id:0,
        out: '',
        forward: '',
        phone: '',
    })

    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [phone,setPhone] = useState([])
    const [out,setOut] = useState([])
    const [loading,setLoading] = useState(!!edit)

    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        if (edit) {
            await dispatch(forwardEditRequest(10,query.page || 1,query.search || '', form))
        } else {
            await dispatch(forwardCreateRequest(10,query.page || 1,query.search || '', form))
        }
        console.log(form)
        setOpen(false)
    }, [form, edit])
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getSelect(edit?'edit':'create')

                // if (!edit){
                    setPhone(data.input_number.input)
                // }
                setOut(data.input_number.channel)
            }catch (e) {

            }
        })()
    }, [edit])
    useEffect(() => {
        (async () => {
            try {
                if(edit){
                    const {data} = await Api.getSingle(edit)
                    setForm({
                        id:edit,
                        out: data.single.channel,
                        forward: data.single.output,
                        phone: (data.single.input + '_' + (data.single.provider?data.single.provider:'')),
                        provider: data.single.provider,
                    })

                    if(!data.single.provider){
                        phone.map((item) => {
                            if(item.name === data.single.input && item.provider !== 4){
                                setForm({
                                    id:edit,
                                    out: data.single.channel,
                                    forward: data.single.output,
                                    phone: (data.single.input + '_' + (item.provider?item.provider:'')),
                                    provider: data.single.provider,
                                })
                            }
                        })
                    }
                    setLoading(false)

                }
            }catch (e) {
            }
        })()
    }, [edit,phone])
    if (loading) return null
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {sip.forward[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                    <form onSubmit={handleAdd}>
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                             {included.phone[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <select
                                    disabled={edit}
                                    value={form.phone}
                                    onChange={(ev) => handleChange('phone', ev.target.value)}
                                    style={{height: 40,  border: '1px solid #ccc', outline: 'none'}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                    {/*{edit && inputString?<option value={inputString}>{inputString}</option>:null}*/}
                                    {phone.map((o, _) => (
                                        <option key={_} value={o.name+'_'+(o.provider?o.provider:'')}>{o.title}</option>
                                    ))}
                                </select>
                            </label>

                            <label style={{margin: '20px 0'}} className="count col-md-12">
                            <span>
                                {forward.forward[+localStorage.getItem('atsLang')  || 1 ]}
                            </span>
                                <input value={form.forward} onChange={(ev) => handleChange('forward', ev.target.value)}/>
                            </label>
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                             {sip.out[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <select
                                    value={form.out} onChange={(ev) => handleChange('out', ev.target.value)}
                                    style={{height: 40,  border: '1px solid #ccc', outline: 'none'}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {out.map((o, _) => (
                                        <option key={_} value={o.name}>{o.title}</option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {edit?global.edit[+localStorage.getItem('atsLang')  || 1 ]:sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}

                            </button>
                        </div>
                    </form>

                </Box>
            </Fade>
        </Modal>
    );
}

export default AddAndEditForward;
