import React, {useEffect, useState} from 'react';
import img from '../assets/img/logo22.png'
import uc from '../assets/img/image_ucom.png'
import footer from '../assets/img/logo2.png'
import MenuIcon from '@mui/icons-material/Menu';
import {Link, useNavigate} from "react-router-dom";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Cookies from 'js-cookie'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Menu from "./Menu";
import LangPop from "./LangPop";
import {menu} from "../translate";
import Account from "../helpers/Account";
import {useDispatch, useSelector} from "react-redux";
import {moneyRequest, oggRequest, priceRequest, profileRequest, profileUrsRequest} from "../store/actions/users";
import Money from "./Money";
import Api from "../Api";
import {Tooltip} from 'react-tooltip';
import ats from '../assets/img/logo_light.png'
import footer_img from '../assets/img/header_logo.svg'
import Loader from "./Loader";
import {REACT_APP_API_URL} from '../config';

const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function Wrapper({children}) {
    const [lang, setLang] = useState(0)
    const [open, setOpen] = useState(false)
    const [isAside, setIsAside] = useState(false)
    const [loader, setLoader] = useState(true)
    const [scroll, setScroll] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.users.userData)
    const money = useSelector((state) => state.users.money)
    const prices = useSelector((state) => state.users.prices)
    const ogg = useSelector((state) => state.users.ogg)
    let num = 0
    const openWindow = (e) => {
        e.preventDefault();
        window.open(
            "/online",
            "_blank",
            "width=600,height=300,noopener,noreferrer"
        );
    };
    useEffect(() => {
        if (+localStorage.getItem('atsLang')) {
            setLang(+localStorage.getItem('atsLang'))
        } else {
            localStorage.setItem('atsLang', '1')
        }
    }, [])
    useEffect(() => {
        if(REACT_APP_SERVER === 'ATS'){
            document.body.style.backgroundImage = 'none'
            if(document.getElementsByTagName('header')[0]){
                document.getElementsByTagName('header')[0].style.backgroundImage = 'none'
            }
            if(document.getElementsByTagName('footer')[0]){
                document.getElementsByTagName('footer')[0].style.backgroundImage = 'none'
            }
        }
    }, [REACT_APP_SERVER])

    useEffect(() => {
        (async () => {
            if(Cookies.get('buddy') && Cookies.get('buddy').length === 7){
                window.location.href=`${REACT_APP_API_URL}/operator`
            }
            else if (!Account.getAdminToken()) {
                navigate('/login')
            } else {
                await dispatch(profileRequest())
                if (Cookies.get('viewer')) {
                    await dispatch(profileUrsRequest())
                }
            }
            await dispatch(moneyRequest())
            await dispatch(priceRequest())
            await dispatch(oggRequest())
        })()
    }, [])

    useEffect(() => {
        (async () => {
            try {
                if(prices?.length && money?.mappedData?.length) {
                    let commonObjects = prices.filter(obj1 => money.mappedData.some(obj2 => obj2.name === obj1.sname));
                    let commonObjects1 = prices.filter(obj1 => !money.mappedData.some(obj2 => obj2.name === obj1.sname));
                    commonObjects = commonObjects.filter(l => l.sname !== 'CRMSTP' && l.sname !== 'MOB' && l.sname !== 'REC').map(l => ({
                        name: l.sname,
                        val: color,
                        percent: l.percent || 0
                    }))
                    commonObjects1 = commonObjects1.filter(l => l.sname !== 'CRMSTP' && l.sname !== 'MOB' && l.sname !== 'REC').map(l => ({
                        name: l.sname,
                        val: 'red',
                        percent: l.percent || 0
                    }));
                    setScroll([...commonObjects, ...commonObjects1])
                    num = 0
                    setLoader(false)
                }else if(prices?.length && !money?.mappedData?.length){
                    setLoader(false)
                }
            } catch (e) {
                setLoader(false)

                console.log(e)
            }
        })()
    }, [money,prices])
    return (
        <>
            <header className="header">
                <div className="header_container container" style={window.innerWidth > 1030 ? {} : {maxWidth: '100%'}}>
                    <div className="header_block">
                        <div className="left">
                            <Link to={Cookies.get('viewer') ? '/urs' : "/"}>
                                {REACT_APP_SERVER === 'Beeline' ? <figure className="justify-content-end">

                                    <img src={img} style={window.innerWidth > 565?{width: 100}:{width: 80}} alt=''/>
                                </figure> : REACT_APP_SERVER === 'ATS' ? <figure>

                                    <img src={ats} style={window.innerWidth > 565?{width: 120}:{width: 80}} alt=''/>
                                </figure> : <figure>

                                    <img src={uc} alt='' style={window.innerWidth > 565?{}:{width: 110}} />
                                    <figcaption className="v_office">V-office</figcaption>
                                </figure>}
                            </Link>
                            {window.innerWidth > 565 ? <>
                                    <div className="header_food">
                                        <Link to='/clgroup' className="truncate"> {userData?.fio}</Link>
                                        <p>Inn: {userData?.phone}</p>
                                    </div>

                                    <div className="header_border"/>
                                </>
                                : null}
                            <div className="header_balance">
                                <div onClick={() => setOpen(true)}>
                                    {menu.amount[lang || 1]}
                                </div>
                                <p>
                                    {+userData?.gumar || 0} amd
                                </p>
                            </div>


                            <div className="header_border"/>
                            {window.innerWidth > 1250 ? <>
                                    <div className="header_Finance">
                                        <Link
                                            to="/online"
                                            onClick={openWindow}
                                            ><AccessTimeFilledIcon
                                            sx={{color: '#F28E2C', fontSize: 20}}/> {menu.online[lang || 1]}</Link>
                                    </div>
                                    <div className="header_border"/>
                                </>
                                : null}
                            {window.innerWidth > 1250 ?
                                <>
                                    <div className="header_Finance">
                                        <Link to="/history"><NewspaperIcon
                                            sx={{color: 'orangered', fontSize: 20}}/> {menu.history[lang || 1]}</Link>
                                    </div>
                                    <div className="header_border"/>

                                </>
                                : null}
                            {/*{window.innerWidth > 1250 ? <div className="header_Finance">*/}
                            {/*    <span><ContactEmergencyIcon sx={{color: '#CE405F', fontSize: 20}}/> Պատմություն</span>*/}
                            {/*</div> : null}*/}

                        </div>
                        <div className="right">
                            {/*{window.innerWidth > 565 && userData.id === 1 && REACT_APP_SERVER === 'Beeline' ? <div className="profile">*/}
                            {/*    <Link to='/crm_integration' style={{marginRight:5,}}><AccountCircleIcon*/}
                            {/*        sx={{color: '#2cf2eb', fontSize: 20}}/> {sip.crm[lang]}</Link>*/}
                            {/*</div> : null}*/}

                            {window.innerWidth > 565 ? <div className="profile">
                                <Link to='/profile'><AccountCircleIcon
                                    sx={{color: '#F28E2C', fontSize: 20}}/> {menu.profile[lang || 1]}</Link>
                            </div> : null}

                            {window.innerWidth > 1250 ? <div className="logout">
                                <Link to="/login"><LockIcon sx={{color: '#CD405F', fontSize: 20}}/> {menu.logout[lang || 1]}
                                </Link>
                            </div> : null}

                            {window.innerWidth < 1250 ?
                                <div onClick={() => setIsAside(!isAside)} style={{margin: '0 10px'}}
                                     className="d-flex align-items-center justify-content-center">
                                    <MenuIcon/>
                                </div> : null}
                        </div>

                    </div>
                </div>
            </header>

            <main className={window.location.pathname === '/files'?"main container overflow-auto":'main container'} style={window.innerWidth > 1030 ? {} : {maxWidth: '100%'}}>
                {window.innerWidth > 585?loader ? <div className="loaderContainer"><Loader/></div> :
                    <div style={{width: '100%', marginTop: 20, height: 45}}
                         className="d-flex flex-row align-items-center position-relative">
                    <span
                        data-tooltip-content={ogg[lang - 1 || 0]}
                        className="d-flex flex-row align-items-center justify-content-center"
                        style={{
                            // border: window.innerWidth <= 320 ? '1px solid #fff' : '5px solid #fff',
                            // fontSize: window.innerWidth <= 320 ? 8 : 15,
                            // boxShadow: '0px 0px 7px 0px #00000054',
                            // background: '#7ebc0a',
                            display: 'inline-block',
                            // width: window.innerWidth <= 320 ? 30 : 65,
                            // height: window.innerWidth <= 320 ? 30 : 65,
                            // borderRadius: '100%',
                            paddingLeft: 5,
                            color: 'black',
                            cursor: 'pointer',
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                        data-tooltip-id="my-tooltip"
                    >{ogg[lang - 1 || 0]?.split(' ').map(item => item.charAt(0).toUpperCase()).join('')}</span>
                        {scroll.map((el, _) => {
                            if (el.val !== 'red') {
                                num += el.percent
                            }
                            if (scroll[_ + 1] && scroll[_ + 1].val === 'red' && scroll[_].val === color && _ !== scroll?.length - 1 && num !== 100) {
                                return (
                                    <div style={{left: `calc(${num}% - 25px)`}}
                                         className="d-flex flex-row align-items-center position-relative" key={el.name}>
                                    <span
                                        className="d-flex flex-row align-items-center justify-content-center"
                                        style={{
                                            border: '5px solid #fff',
                                            fontSize: 15,
                                            boxShadow: '0px 0px 7px 0px #00000054',
                                            background: el.val,
                                            display: 'inline-block',
                                            width: 50,
                                            height: 50,
                                            borderRadius: '100%',
                                            color: 'white'

                                        }}
                                        data-tooltip-id="my-tooltip"
                                    >
                            {num}%
                            </span>
                                    </div>
                                )
                            }

                        })}
                        <span
                            className="d-flex flex-row align-items-center justify-content-center position-absolute"
                            style={{
                                border: window.innerWidth <= 320 ? '1px solid #fff' : '5px solid #fff',
                                fontSize: window.innerWidth <= 320 ? 8 : 15,
                                boxShadow: '0px 0px 7px 0px #00000054',
                                background: scroll[scroll?.length - 1]?.val !== 'red' || num === 100 ? color : '#ccc',
                                display: 'inline-block',
                                width: window.innerWidth <= 320 ? 20 : 50,
                                height: window.innerWidth <= 320 ? 20 : 50,
                                borderRadius: '100%',
                                color: 'white',
                                right: 0,
                                textAlign: 'center',
                            }}
                            data-tooltip-id="my-tooltip"
                        >100%</span>
                        <span style={{
                            width: 'calc(100% - 45px)',
                            height: '15px',
                            display: 'inline-block',
                            background: num === 0 ? "white" : `linear-gradient(to right, ${color} ${num}%, white ${num + 10}%)`,
                            boxShadow: '4px 0px 7px 0px #00000054',
                            position: 'absolute',
                            zIndex: -1,
                            right: 0
                        }}/>
                    </div>:null}
                <Tooltip id="my-tooltip"/>
                <div className="main_container" style={{minHeight: '90vh'}}>
                    <Menu isAside={isAside} lang={lang || 1}/>
                    <div
                        className="table_content"
                        style={window.location.pathname !== '/history' && window.location.pathname !== '/graphic' && window.innerWidth > 1030 ? {width: '76%'} : {width: '100%'}}>
                        {children}
                    </div>
                </div>
            </main>
            <footer className="footer">
                <div className="footer_container container">
                    <div className="row">
                        <figure className="col-md-4">
                            <img style={REACT_APP_SERVER === 'ATS' ? {height: '79%'} : {}}
                                 src={REACT_APP_SERVER === 'ATS' ? footer_img : footer} alt=""/>
                        </figure>
                        <div style={{cursor: 'pointer'}} className="col-md-4  languages">
                            <LangPop setLang={setLang}/>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-end justify-content-center flex-column"
                                 style={{minHeight: 80}}>
                                <p><b>Phone:</b> +37411720101</p>
                                <p> +37444720101</p>
                                <p><b>email:</b> info@ats.am
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                {open ? <Money open={open} setOpen={setOpen}/> : null}

            </footer>

        </>
    );
}

export default Wrapper;
