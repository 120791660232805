import React, {useCallback, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {global, input, sip} from "../translate";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 550 ? 550 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function OperatorAccess({open, setOpen, setForm, form}) {
    const handleChange = useCallback((name, ev) => {
        setForm((prev) => ({...prev, [name]: ev}))
    }, [])
    const single = useSelector(state => state.sip.sipSingleData)
    const [copy, setCopied] = useState(false)

    const handleSubmit = useCallback(() => {
        setOpen(false)
    }, [])
    const handleCopy = useCallback((code) => {
        if (navigator.clipboard) {
            setCopied(true)
            navigator.clipboard.writeText(`${single.buddy} - ${form.pass || single?.sip_buddy?.secret}`).catch(function () {});
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    },[single,form])
    const handleGenerate = useCallback(() => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 12; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            password += chars.charAt(randomIndex);
        }
        setForm((prev) => ({...prev, pass: password,reenter:password}))
    }, [])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {sip.access[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setOpen(false)
                        }}>
                            <CloseIcon/>

                        </span>
                    </div>
                    <form onSubmit={handleSubmit} id="transition-modal-description sip_content" className="test_mode">
                        <div className="row">
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                                <span>{sip.accessT[+localStorage.getItem('atsLang') || 1]}</span>
                                <select value={form.history}
                                        onChange={(ev) => handleChange('history', +ev.target.value)}
                                        style={{height: 40, border: '1px solid #ccc',}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                    <option value="11">{sip.history1[+localStorage.getItem('atsLang') || 1]}</option>
                                    <option value="10">{sip.history[+localStorage.getItem('atsLang') || 1]}</option>
                                    <option value="12">{sip.sipHistory[+localStorage.getItem('atsLang') || 1]}</option>
                                    <option value="13">{sip.sipHistory1[+localStorage.getItem('atsLang') || 1]}</option>
                                </select>
                            </label>
                            <div className="col-md-12 count" style={{margin: '20px 0'}}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="addBtnBig" onClick={handleGenerate} style={{margin: '20px 0'}}>
                                        {sip.generate[+localStorage.getItem('atsLang') || 1]}
                                    </div>
                                    <div className="d-flex align-items-center" style={{margin:10,border:'1px solid #ccc',width:'fit-content'}}>
                                        <pre id="amoKey" className="m-0" style={{background: '#f1faf5', padding: 10, margin:0}}>{single.buddy} - {form.pass || single?.sip_buddy?.secret} </pre> <p onClick={handleCopy} style={{ margin: '0 20px',cursor:'pointer',fontSize:20}}><FontAwesomeIcon icon={faCopy} /></p>
                                    </div>
                                </div>
                            </div>
                            <label className="col-md-6 count" style={{margin: '10px 0 20px'}}>
                                <span>{sip.pass[+localStorage.getItem('atsLang') || 1]}</span>
                                <input value={form.pass || ''}
                                       onChange={(ev) => handleChange('pass', ev.target.value)}/>
                            </label>
                            <label className="col-md-6 count" style={{margin: '10px 0 20px'}}>
                                <span>{sip.reenter[+localStorage.getItem('atsLang') || 1]}</span>
                                <input value={form.reenter || ''}
                                       onChange={(ev) => handleChange('reenter', ev.target.value)}/>
                            </label>
                            <div style={{marginTop: 20}} className="d-flex justify-content-center">
                                <button className="addBtnBig" onClick={handleSubmit}>
                                    {global.edit[+localStorage.getItem('atsLang') || 1]}
                                </button>
                            </div>
                        </div>
                    </form>
                    {copy ? <div className="copied">Copied</div> : null}

                </Box>
            </Fade>
        </Modal>
    );
}

export default OperatorAccess;