import React, {useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import Account from "../helpers/Account";
import Api from "../Api";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TestMode from "../components/TestMode";
import ForumIcon from '@mui/icons-material/Forum';
import Comment from "../components/Comment";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {profileRequest, profileUrsRequest} from "../store/actions/users";
import Cookies from "js-cookie";
import qs from "query-string";
import Billing from "../components/Billing";
import UrsAdd from "../components/UrsAdd";
import UrsReport from "../components/UrsReport";
import UrsVoip from "../components/UrsVoip";
import {Helmet} from "react-helmet";
import {REACT_APP_API_URL} from '../config';
import BillingPop from "../components/BillingPop";
import TestPop from "../components/TestPop";
import DisabledPop from "../components/DisabledPop";
import MatrixPop from "../components/MatrixPop";
import UrsHeader from "../components/UrsHeader";
import {Tooltip} from 'react-tooltip';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const {REACT_APP_SERVER} = process.env;

function Urs(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const profile = useSelector(state => state.users.ursData)
    const [data, setData] = useState({})
    const [test, setTest] = useState({id: 0})
    const [comment, setComment] = useState(false)
    const [billing, setBilling] = useState({member: 0, name: ''})
    const [add, setAdd] = useState(false)
    const [search, setSearch] = useState('')
    const [gumar, setGumar] = useState('')
    const [report, setReport] = useState('')
    const [voip, setVoip] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (!Account.getUrsToken()) {
            navigate('/admin_login')
        }
    }, [])
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setData([])
                const {data} = await Api.members(query.page || 1, query.search || '', query.billing || '', query.testmode || '', query.isblocked || '', query.matrix || '', query.gumar || '',query.isActive || 'all')
                setData(data)
                setSearch(query.search || '')
                setLoading(false)

            } catch (e) {
            }
        })()
    }, [location.search])

    useEffect(() => {
        (async () => {
            await dispatch(profileUrsRequest())
        })()
    }, [])

    const handlePagination = useCallback(async (num) => {
        query.page = num;
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    const handleTest = useCallback(async (id) => {
        try {
            for (const idElement of data.groupedArray) {
                for (const member of idElement.members) {
                    if (+member.id === +id) {
                        if (member.testmode) {
                            await Api.test_mode(id, 'off', location.pathname)
                        }
                    }
                }
            }

            setData((prevData) => {
                const updatedGroupedArray = prevData.groupedArray.map((prevDatum) => {
                    const updatedMembers = prevDatum.members.map((member) => {
                        if (+member.id === +id) {
                            if (!member.testmode) {
                                setTest(member);
                                return member;
                            } else {
                                return {...member, testmode: !member.testmode, test_period: null};
                            }
                        }
                        return member;
                    });

                    return {...prevDatum, members: updatedMembers};
                });

                return {...prevData, groupedArray: updatedGroupedArray}; // Update the entire state
            });
        } catch (e) {
            console.log(e)
            toast.error(e.response.data.message)
        }
    }, [data]);

    const handleId = useCallback(async (id) => {
        try {
            Cookies.set('viewer', id)
            Cookies.set('original', profile.id)
            Cookies.set('key', profile.uid)
            const {data} = await Api.loginAsUser(id, profile.id, profile.uid)
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            await dispatch(profileRequest())
            window.open(REACT_APP_API_URL, '_blank');
            // navigate('/')
        } catch (e) {

        }
    }, [profile, REACT_APP_API_URL])
    const handleSearch = useCallback((ev) => {
        ev.preventDefault()
        query.search = search;
        if (gumar) {
            query.gumar = gumar;
        }
        query.page = '';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [search, location.search, gumar])

    const handleActive = useCallback((ev) => {
        query.isActive = ev;
        console.log(ev)
        if (gumar) {
            query.gumar = gumar;
        }
        if (search) {
            query.search = search;
        }
        query.page = '';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [search, location.search, gumar])
    const handleBlock = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to change status?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите изменить?" :
                    "Վստա՞հ եք, որ ցանկանում եք փոփոխել տողը";

            if (confirm(text) === true) {
                try {
                    setLoading(true)
                    for (const idElement of data.groupedArray) {
                        for (const member of idElement.members) {
                            if (+member.id === +id) {
                                await Api.blocked(id, +!member.isblocked)
                            }
                        }
                    }
                    setData((prevData) => {
                        const updatedGroupedArray = prevData.groupedArray.map((prevDatum) => {
                            const updatedMembers = prevDatum.members.map((member) => {
                                if (member.id === id) {
                                    if (member.isblocked) {
                                        return {...member, testmode: 0, isblocked: !member.isblocked};
                                    } else {
                                        return {...member, isblocked: !member.isblocked};

                                    }
                                }
                                return member;
                            });

                            return {...prevDatum, members: updatedMembers};
                        });

                        return {...prevData, groupedArray: updatedGroupedArray}; // Update the entire state
                    });
                    setLoading(false)
                } catch (e) {
                    toast.error(e.response.data.message)
                }

            }
        } catch (e) {
            console.log(e)
        }
    }, [data])
    const [isOpen, setIsOpen] = useState('')

    const handleChange = useCallback(async (ev) => {
        try {
            if(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'].includes(ev.target.files[0].type) || (ev.target.files[0].name?.endsWith('.xlsx') || ev.target.files[0].name?.endsWith('.csv'))){
                toast.info('Please wait')
                let {data} = await Api.uploadINT(ev.target.files[0])
                console.log(data.exelArr,)
                data = [...data.exelArr,...data.memberArr]

                if(data.length){
                    toast.success('Success. Downloading not changed data')
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                    const fileData = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    saveAs(fileData, "data.xlsx");
                }else{
                    toast.success('Success')
                }

            }else{
                toast.error('It should be an exel file')
            }
        }catch (e) {

        }
    },[])
    return (
        <>
            <Helmet>
                <title>Admin Panel</title>
            </Helmet>
            <UrsHeader/>
            <div className="urs_table" style={{display: 'flex', justifyContent: 'center'}}>
                <div className="container tableParent  file" style={window.innerWidth <= 1024 ? {
                    overflow: 'auto',
                    background: 'white',
                    padding: 35
                } : {background: 'white', padding: 35}}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div>
                                <span className="custom_span" style={{cursor: 'pointer'}}
                                      onClick={() => setReport('prev')}>Download Previous Month Report</span> / <span
                                className="custom_span"
                                style={{cursor: 'pointer'}}
                                onClick={() => setReport('now')}>Download Month Report</span></div>
                            <hr/>
                            <div>
                                <span className="custom_span" style={{cursor: 'pointer', marginRight: 5}}
                                      onClick={() => setAdd(true)}>Add</span>
                                |
                                <span className="custom_span" style={{cursor: 'pointer', margin: 5}}
                                      onClick={() => setVoip(true)}>VoIP Report</span>
                                |
                                {!profile.isLimited ?
                                    <Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/urs_used"
                                          target="_blank"
                                          rel="noopener noreferrer">{REACT_APP_SERVER === 'Beeline' ? 'Beeline' : 'V-Office'} Used</Link> : null}
                                |<Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/report">Report
                                Traffic </Link> |
                                {!profile.isLimited ?
                                    <label>
                                        <span className="custom_span" style={{cursor: 'pointer', margin: 5}} >Վերբեռնել ՀՎՀՀ</span>
                                        <input onChange={(ev) => handleChange(ev)} className="d-none" type="file"/>

                                    </label> : null}
                            </div>
                            <div className="custom_span">Members: {data.total}</div>
                            <div
                                className="custom_span">Pages: {Array.from({length: Math.ceil(data.totalPages / 50)}).map((_, i) => (
                                <span className="custom_span" style={{cursor: 'pointer'}}
                                      onClick={() => handlePagination(i + 1)}
                                      key={i}>{i + 1} </span>))}</div>

                            <div className="custom_div d-flex align-items-center">Member Type: <span onClick={() =>handleActive('all')}>All</span> | <span onClick={() =>handleActive('inactive')}>Registered</span> | <span onClick={() =>handleActive('active')}>Added</span></div>

                        </div>
                        <form onSubmit={handleSearch} className="d-flex flex-column align-items-start search_block"
                              style={{padding: '17px 0'}}>
                            <div>
                                <input type="text" placeholder="Գումար" value={gumar}
                                       onChange={(e) => setGumar(e.target.value)}/>
                                <input type="text" placeholder="Համար" value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                            <button onClick={handleSearch} style={{padding: '2px 30px', margin: '10px 0'}}
                                    className="addBtn">Search
                            </button>
                        </form>
                    </div>
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>NN</th>
                            <th>Ամսաթիվ</th>
                            <th>Օգտատեր</th>
                            <th><MatrixPop/></th>
                            <th>Հեռ</th>
                            <th>Անուն Ազգանուն</th>
                            <th>VIP</th>
                            <th>Մնացորդ (Դրամ)</th>
                            <th>Ծառայության գին</th>
                            <th><TestPop/></th>
                            <th><BillingPop billing={data.billings}/></th>
                            <th><DisabledPop/></th>
                            <th>Disabl. Date</th>
                            <th>H</th>
                            <th>R</th>
                            <th>L</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? <tr>
                            <td style={{textAlign: 'center'}} colSpan={17}>Loading...</td>
                        </tr> : data.groupedArray?.length ? data.groupedArray.map(d => {
                            if (d?.members?.length === 1) {
                                return (<tr className={d.members[0]?.isactive?"not_finished":""} key={d.members[0]?.id} style={{cursor: 'pointer'}}>
                                    <td>{d.members[0]?.id}</td>
                                    <td>{new Date(d.members[0]?.registered).toLocaleString()}</td>
                                    <td onClick={() => handleId(d.members[0]?.id)}>{d.members[0]?.name}</td>
                                    <td>{d.members[0]?.matrixid}</td>
                                    <td>{d.members[0]?.phone}</td>
                                    <td>{d.members[0]?.fio}</td>
                                    <td>{d.members[0]?.isvip}</td>
                                    <td>{d.members[0]?.balance}</td>
                                    <td>{d.members[0]?.sgs} / {d.members[0]?.sg}</td>
                                    <td>
                                    <span data-tooltip-content={new Date(d.members[0]?.testDate).toLocaleString()} data-tooltip-id={d.members[0]?.testDate} className="d-flex align-items-end">
                                        <input className="m-0" type="checkbox"
                                               checked={d.members[0]?.testmode}
                                               onChange={() => handleTest(d.members[0]?.id)}/>
                                        <span style={{fontSize: 'small'}}><CalendarMonthIcon
                                            onClick={() => setTest(d.members[0])} style={{fontSize: 16}}/></span></span>
                                    </td>
                                    <td onClick={() => {
                                        setBilling({
                                            member: d.members[0]?.id,
                                            name: data?.billings[d.members[0]?.admgroup]
                                        })
                                    }}>
                                        {data?.billings[d.members[0]?.admgroup]}
                                    </td>
                                    <td><span className="d-flex align-items-end justify-content-center"><input
                                        className="m-0" type="checkbox"
                                        checked={d.members[0].isblocked}
                                        onChange={() => handleBlock(d.members[0].id)}/></span>
                                    </td>
                                    <td>{d.members[0].sdate ? new Date(d.members[0].sdate).toLocaleString() : ''}</td>
                                    <td>{d.members[0].history}</td>
                                    <td>{d.members[0].lsum}</td>
                                    <td>{d.members[0].dlinesSum}</td>
                                    <td><span className="position-relative" onClick={() => setComment(d.members[0].id)}><ForumIcon
                                        style={{fontSize: 24}}/>
                                        {d.members[0].comment_count ? <span
                                            className="position-absolute comment_count">{d.members[0].comment_count}</span> : null}
                                </span>
                                        <Tooltip className={"tooltip"} id={d.members[0]?.testDate}/>

                                    </td>

                                </tr>)
                            } else {
                                return d?.members?.map((r, ind) => {
                                    if (ind === 0) {
                                        return (<tr className={d.members[0]?.isactive?"not_finished":""} key={d?.members[0]?.id}
                                                    style={{cursor: 'pointer', background: '#e5e5e5',}}>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.id}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{new Date(d.members[0]?.registered).toLocaleString()}</td>
                                            <td onClick={() => handleId(d.members[0]?.id)}>{d.members[0]?.name}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}> {d.members[0]?.matrixid}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.phone}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.fio}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.isvip}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.balance}</td>
                                            <td onClick={() => setIsOpen(isOpen === r.id ? '' : r.id)}>{d.members[0]?.sgs} / {d.members[0]?.sg}</td>
                                            <td>
                                    <span data-tooltip-content={new Date(d.members[0]?.testDate).toLocaleString()} data-tooltip-id={d.members[0]?.testDate} className="d-flex align-items-end">
                                        <input className="m-0" type="checkbox"
                                               checked={d.members[0]?.testmode}
                                               onChange={() => handleTest(d.members[0]?.id)}/>
                                        <span style={{fontSize: 'small'}}><CalendarMonthIcon
                                            onClick={() => setTest(d.members[0])} style={{fontSize: 16}}/></span></span>
                                            </td>
                                            <td onClick={() => {
                                                setBilling({
                                                    member: d.members[0]?.id,
                                                    name: data?.billings[d.members[0]?.admgroup]
                                                })
                                            }}>
                                                {data?.billings[d.members[0]?.admgroup]}
                                            </td>
                                            <td><span className="d-flex align-items-end justify-content-center"><input
                                                className="m-0" type="checkbox"
                                                checked={d.members[0].isblocked}
                                                onChange={() => handleBlock(d.members[0].id)}/></span>
                                            </td>
                                            <td>{d.members[0].sdate ? new Date(d.members[0].sdate).toLocaleString() : ''}</td>
                                            <td>{d.members[0].history}</td>
                                            <td>{d.members[0].lsum}</td>
                                            <td>{d.members[0].dlinesSum}</td>
                                            <td><span className="position-relative"
                                                      onClick={() => setComment(d.members[0].id)}><ForumIcon
                                                style={{fontSize: 24}}/>
                                                {d.members[0].comment_count ? <span
                                                    className="position-absolute comment_count">{d.members[0].comment_count}</span> : null}
                                </span>
                                                <Tooltip className={"tooltip"} id={d.members[0]?.testDate}/>

                                            </td>

                                        </tr>)
                                    } else {
                                        return (
                                            <tr
                                                className={r.isactive?"not_finished":""}
                                                style={isOpen !== r.clgroup ? {
                                                display: 'none',
                                                cursor: 'pointer'
                                            } : {background: '#efefef', cursor: 'pointer'}} key={r?.id}>
                                                <td>{r?.id}</td>
                                                <td>{new Date(r?.registered).toLocaleString()}</td>
                                                <td onClick={() => handleId(r?.id)}>{r?.name}</td>
                                                <td>{r?.matrixid}</td>
                                                <td>{r?.phone}</td>
                                                <td>{r?.fio}</td>
                                                <td>{r?.isvip}</td>
                                                <td>{r?.balance}</td>
                                                <td>{r?.sgs} / {r?.sg}</td>
                                                <td>
                                    <span data-tooltip-content={new Date(d.members[0]?.testDate).toLocaleString()} data-tooltip-id={d.members[0]?.testDate} className="d-flex align-items-end">
                                        <input className="m-0" type="checkbox"
                                               checked={r?.testmode}
                                               onChange={() => handleTest(r?.id)}/>
                                        <span style={{fontSize: 'small'}}><CalendarMonthIcon
                                            onClick={() => setTest(r)} style={{fontSize: 16}}/></span></span></td>
                                                <td onClick={() => {
                                                    setBilling({member: r?.id, name: data?.billings[r?.admgroup]})
                                                }}>
                                                    {data?.billings[r?.admgroup]}
                                                </td>
                                                <td><span
                                                    className="d-flex align-items-end justify-content-center"><input
                                                    className="m-0" type="checkbox"
                                                    checked={r.isblocked}
                                                    onChange={() => handleBlock(r.id)}/></span>
                                                </td>
                                                <td>{r.sdate ? new Date(r.sdate).toLocaleString() : ''}</td>
                                                <td>{r.history}</td>
                                                <td>{r.lsum}</td>
                                                <td>{r.dlinesSum}</td>
                                                <td><span className="position-relative"
                                                          onClick={() => setComment(r.id)}><ForumIcon
                                                    style={{fontSize: 24}}/>
                                                    {r.comment_count ? <span
                                                        className="position-absolute comment_count">{r.comment_count}</span> : null}
                                </span>
                                                    <Tooltip className={"tooltip"} id={d.members[0]?.testDate}/>

                                                </td>
                                            </tr>)
                                    }
                                })
                            }
                        }) : data.status === 'ok' && !data?.groupedArray?.length ?
                            <tr>
                                <td style={{textAlign: 'center'}} colSpan={17}>No Data</td>
                            </tr> : <tr>
                                <td colSpan={17}/>
                            </tr>


                        }
                        {+profile.admingroup === 1 && profile.price_permision ? <tr>
                            <td colSpan={15}>
                                All
                            </td>
                            <td colSpan={1}>
                                {data.totalSggumar} / {data.totalSggumarc}
                            </td>
                            <td colSpan={1}>
                                {data.gumarall || 0} / {Math.round(data.gumarall * 0.7 / 1.2) || 0}
                            </td>
                        </tr> : null}
                        </tbody>

                    </table>
                </div>
            </div>
            {test.id ? <TestMode open={test} setOpen={setTest} setData={setData}/> : null}
            {comment ? <Comment open={comment} setOpen={setComment}/> : null}
            {add ? <UrsAdd open={add} setOpen={setAdd}/> : null}
            {billing.member ? <Billing open={billing} setOpen={setBilling} setData={setData}/> : null}
            {report ? <UrsReport open={report} setOpen={setReport}/> : null}
            {voip ? <UrsVoip open={voip} setOpen={setVoip}/> : null}
        </>
    );
}

export default Urs;
