export const FILE_REQUEST = 'FILE_REQUEST';
export const FILE_SUCCESS = 'FILE_SUCCESS';
export const FILE_FAIL = 'FILE_FAIL';

export function fileRequest() {
  return {
    type: FILE_REQUEST,
    payload: { },
  };
}

export const FILE_CREATE_REQUEST = 'FILE_CREATE_REQUEST';
export const FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS';
export const FILE_CREATE_FAIL = 'FILE_CREATE_FAIL';

export function fileCreateRequest(form) {
  return {
    type: FILE_CREATE_REQUEST,
    payload: { form },
  };
}
export const FILE_UPDATE_REQUEST = 'FILE_UPDATE_REQUEST';
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS';
export const FILE_UPDATE_FAIL = 'FILE_UPDATE_FAIL';

export function fileUpdateRequest(id,musicHold,type) {
  return {
    type: FILE_UPDATE_REQUEST,
    payload: { id,musicHold,type },
  };
}

export const FILE_IDICT_CREATE_REQUEST = 'FILE_IDICT_CREATE_REQUEST';
export const FILE_IDICT_CREATE_SUCCESS = 'FILE_IDICT_CREATE_SUCCESS';
export const FILE_IDICT_CREATE_FAIL = 'FILE_IDICT_CREATE_FAIL';

export function fileIdictCreateRequest(form) {
  return {
    type: FILE_IDICT_CREATE_REQUEST,
    payload: { form },
  };
}

export const FILE_YANDEX_CREATE_REQUEST = 'FILE_YANDEX_CREATE_REQUEST';
export const FILE_YANDEX_CREATE_SUCCESS = 'FILE_YANDEX_CREATE_SUCCESS';
export const FILE_YANDEX_CREATE_FAIL = 'FILE_YANDEX_CREATE_FAIL';

export function fileYandexCreateRequest(form) {
  return {
    type: FILE_YANDEX_CREATE_REQUEST,
    payload: { form },
  };
}

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAIL = 'FILE_DELETE_FAIL';

export function fileDeleteRequest(id) {
  return {
    type: FILE_DELETE_REQUEST,
    payload: { id },
  };
}
export const FILE_ADD_LOCAL = 'FILE_ADD_LOCAL';

export function fileLocal(data) {
  return {
    type: FILE_ADD_LOCAL,
    payload: { data },
  };
}
export const FILE_DELETE_LOCAL = 'FILE_DELETE_LOCAL';

export function fileDeleteLocal(id) {
  return {
    type: FILE_DELETE_LOCAL,
    payload: { id },
  };
}



