import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {auto, did, global, included, input, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {
    sipLocalRequest,
    sipProviderCreateRequest,
    sipProviderEditRequest,
    sipProviderLocalRequest
} from "../store/actions/sip";
import {include} from "../store/actions/included";
import {didCreateGet, didLocal} from "../store/actions/did";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function AddAndEditSip({open,edit, setOpen}) {
    const [form, setForm] = useState({
        provider:'',
        username:'',
        password:'',
        notes:'',
        lines:1,
        server_host:'',
        caller_visible_type:0
    })

    const dispatch = useDispatch()
    const didCreateData = useSelector(state => state.did.didCreateData)
    const [loading,setLoading] = useState(!!edit)
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        if(edit){
            await dispatch(sipProviderEditRequest(form))
        }else{
            await dispatch(sipProviderCreateRequest(form))
        }
        setOpen(false)
    }, [form,edit])
    useEffect(() => {
        (async () => {
            await dispatch(didCreateGet())
        })()
    }, [])
    useEffect(() => {
        if(edit){
            (async () => {
               try {
                   const {data} = await Api.getSingleProvider(edit)
                   setForm({
                       id:data.data.id,
                       provider:didCreateData?.fix_provider?.find(s => s.title === data.data.name)?.name,
                       username:data.data.username,
                       password:data.data.password,
                       notes:data.data.notes,
                       lines:data.data.lines,
                       caller_visible_type:data.data.caller_visible_type,
                       server_host:data.data.server_host,
                   })
                   setLoading(false)
               }catch (e) {

               }
            })()
        }
    }, [edit,didCreateData])
    if (loading) return null
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {did.sip[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                        <CloseIcon/>

                        </span>
                    </div>

                    <form onSubmit={handleAdd}>
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {did.operator[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <select
                                    value={form.provider} onChange={(ev) => handleChange('provider', ev.target.value)}
                                    style={{height: 40,  border: '1px solid #ccc', outline: 'none'}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {didCreateData?.fix_provider?.map(p => (
                                        <option value={p.name} key={p.name}>{p.title}</option>
                                    ))}
                                </select>
                            </label>

                            <label style={{margin:'20px 0'}} className="count col-md-12">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.notes} onChange={(ev) => handleChange('notes', ev.target.value)}/>
                            </label>
                            {edit?<label className="col-md-12 count" style={{margin: '5px 0'}}>
                                <span className="d-flex align-items-center">
                               {did.visible[+localStorage.getItem('atsLang')  || 1]}: <input style={{marginLeft:20}} className="w-auto" type="checkbox" checked={form.caller_visible_type} onChange={(ev) => handleChange('caller_visible_type', ev.target.checked ? 1 : 0)}/>
                            </span>

                            </label>:null}
                            <label className="count col-md-12">
                            <span>
                               {included.phone[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.username} onChange={(ev) => handleChange('username', ev.target.value)}/>
                            </label>
                            <label style={{margin:'20px 0'}} className="count col-md-12">
                            <span>
                               {sip.pass[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.password} type={edit?'password':'text'} onChange={(ev) => handleChange('password', ev.target.value)}/>
                            </label>
                            <label style={{margin:'20px 0'}} className="count col-md-12">
                            <span>
                               {did.line[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input type="number" value={form.lines} onChange={(ev) => handleChange('lines', ev.target.value)}/>
                            </label>
                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {edit?global.edit[+localStorage.getItem('atsLang')  || 1 ]:sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}

                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddAndEditSip;
