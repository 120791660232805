import React, {useCallback, useEffect, useRef, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Utils from "../Utils";
import {
    addEdge,
    Background,
    Controls,
    MiniMap,
    ReactFlow,
    reconnectEdge,
    useEdgesState,
    useNodesState,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import Paper from "@mui/material/Paper";
import Api from "../Api";
import CustomNode from "../components/CustomNode";
import Select from "react-select";
import {toast} from "react-toastify";
import SipToShort from "../components/SipToShort";


const initialNodes = [];
const nodeTypes = {
    custom: CustomNode,
};
let sourceColorMap = {
    '55': 'blue',
    '2': 'green',
    '3': 'red',
};

const getColorForSource = (source) => sourceColorMap[source] || 'gray';
const initialEdges = [];
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};

function DragConnect(props) {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [type, setType] = useState('')
    const [num, setNum] = useState('')
    const [isActive, setIsActive] = useState({})
    const [nums, setNums] = useState([])
    const edgeReconnectSuccessful = useRef(true);
    useEffect(() => {
        if (type) {
            (async () => {
                const {data} = await Api.type(type);
                let s = data.data.map(d => ({value: d.number, label: `${d.number} ${d.name}`}))
                setNums(s)
            })()
        }
    }, [type])
    useEffect(() => {
        (async () => {
            const {data} = await Api.drag();
            let currentY = 50;
            let labelLength = 0
            let nodeHeight = 0
            let position = 0
            let color = 'white'

            let fetchedEdgeDid = [];
            let fetchedNodesChild = [];
            let m = null
            let fetchedNodesDid = data.data.did.filter((s) => s.child).map((s, _) => {
                labelLength = 20;
                nodeHeight = labelLength * 8;
                position = {x: 0, y: currentY};
                currentY += nodeHeight;
                color = Utils.getRandomColor();
                sourceColorMap = {...sourceColorMap, [s.pref.toString() + s.did.toString()+ s?.provider?.toString()]: color};
                fetchedNodesChild.push({
                    id: (s?.child?.virtual ? s?.child?.virtual?.toString() : s?.child?.buddy?.toString()) +'_'+ s?.pref?.toString() + s?.did?.toString() + s?.provider?.toString(),
                    type: "custom",
                    position: {...position, x: 400},
                    data: {color, uid:s?.child?.id, lid:(s?.child?.virtual ? s?.child?.virtual?.toString() : s?.child?.buddy?.toString()) +'_'+ s?.pref?.toString() + s?.did?.toString() + s?.provider?.toString(), child_type:'SIP',position: {...position, x: 400}, label: `${s?.child?.virtual ? s?.child?.virtual?.toString() : s?.child?.buddy?.toString()}`, type: s?.child?.type?.toUpperCase(),setEdges,setNodes}
                })

                fetchedEdgeDid.push(
                    {
                        id: s?.pref.toString() + s?.did?.toString() + s?.provider?.toString(),
                        source: s?.pref.toString() + s?.did?.toString() + s?.provider?.toString(),
                        sourceHandle: 'DID_source_' + s?.pref?.toString() + s?.did?.toString() + s?.provider?.toString() || 'source',
                        target: s?.redirect?.output+'_'+ s?.pref + s?.did + s?.provider?.toString(),
                        // type: 'step',
                        targetHandle: `${s?.child?.type?.toUpperCase()}_target_` + s?.redirect?.output+'_'+ s?.pref + s?.did+ s?.provider?.toString() || 'target',
                    }
                )
                return ({
                    id: s?.pref?.toString() + s?.did?.toString() + s?.provider?.toString(),
                    type: "custom",
                    position,
                    data: {color, label: `${s?.pref + s?.did} ${s?.notes}`, type: 'DID'}
                })
            })
            setNodes([...fetchedNodesDid, ...fetchedNodesChild])
            setEdges([...fetchedEdgeDid, ])

        })()
    }, [])
    const onReconnectStart = useCallback(() => {
        edgeReconnectSuccessful.current = false;
    }, []);

    const onReconnect = useCallback((oldEdge, newConnection) => {
        edgeReconnectSuccessful.current = true;
        setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    }, []);

    const onReconnectEnd = useCallback((_, edge) => {
        if (!edgeReconnectSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeReconnectSuccessful.current = true;
    }, []);
    const onConnect = useCallback(async (params) => {
            try {
                let {sourceHandle,source,target, targetHandle} = params;
                let from = sourceHandle.split('_')[0]
                let to = targetHandle.split('_')[0]
                let start = source.split('_')[0]
                let end = target.split('_')[0]
                if(from === 'SIP'){
                    start = source.split('_')[1]
                }
                if (from === to) {
                    return
                }else if((from === 'GROUP' && to === 'SIP')|| (from === 'IVR' && to === 'SIP')){
                    setIsActive({from,to,start,end})
                }else{

                    await Api.dragCreate({from,to,start,end})
                    toast.success('Success')
                }
                setEdges((eds) => addEdge(params, eds))

            }catch (e) {
                toast.error('Something went wrong')
            }
        },
        [setEdges]);
    const coloredEdges = edges.map((edge) => ({
        ...edge,
        style: {stroke: getColorForSource(edge.source), strokeWidth: 1, animated: true},
    }));

    const handleChange = useCallback((val) => {
        let color = Utils.getRandomColor();
        const newNode = {
            id: `${val}`,
            type: 'custom',
            position: {  x: 200,y:200 },
            data: {
                color,
                label: val,
                type: type.toUpperCase(),
                uid:val,
                lid:`${val}`,
                child_type:type.toUpperCase()||'SIP',
                position: {  x: 400,y:400 },
                setNodes:setNodes,
                setEdges:setEdges
            },

        }
        setNodes((prevNodes) => [...prevNodes, newNode]);
        setNum(val)
    }, [type, nodes])
    return (
        <Wrapper>
            <div className="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <div style={{padding: 20}}>
                        <h1 className="m-0" style={{fontSize: 20}}>Controllers</h1>
                    </div>
                    <div className="control">
                        <div className="row history_page">
                            <div className="col-md-3">Choose type</div>
                            <label className="col-md-3">
                                <select value={type} onChange={(ev) => setType(ev.target.value)}>
                                    <option value="">
                                        Select
                                    </option>
                                    <option value="did">
                                        DID number
                                    </option>
                                    <option value="sip">
                                        SIP number
                                    </option>
                                    <option value="short">
                                        Short number
                                    </option>
                                    <option value="ivr">
                                        IVR
                                    </option>
                                    <option value="included">
                                        Included number
                                    </option>
                                </select>
                            </label>
                            <div className="col-md-3">Choose element</div>
                            <label className="col-md-3">
                                <Select
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    onChange={(ev) => handleChange(ev.value)}
                                    menuPortalTarget={document.body}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    value={{value: num, label: num}}
                                    name="color"
                                    options={[{value: '', label: "Select"}, ...nums]}
                                />
                            </label>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '100vh'}}>
                        <ReactFlow
                            nodes={nodes}
                            edges={coloredEdges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            onReconnect={onReconnect}
                            onReconnectStart={onReconnectStart}
                            onReconnectEnd={onReconnectEnd}
                        >
                            <Controls/>
                            <MiniMap/>
                            <Background variant="dots" gap={12} size={1}/>
                        </ReactFlow>
                    </div>
                </Paper>
                {isActive.start?<SipToShort open={isActive} setOpen={setIsActive}/>:null}
            </div>
        </Wrapper>
    );
}

export default DragConnect;

