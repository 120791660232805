import {call, put, takeLatest} from 'redux-saga/effects';
import {
    CALL_ROUT_CREATE_FAIL,
    CALL_ROUT_CREATE_REQUEST,
    CALL_ROUT_CREATE_SUCCESS,
    CALL_ROUT_EDIT_FAIL,
    CALL_ROUT_EDIT_REQUEST,
    CALL_ROUT_EDIT_SUCCESS,
    CALL_ROUT_FAIL,
    CALL_ROUT_REQUEST,
    CALL_ROUT_SUCCESS,
} from '../actions/callRout';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleCallRoutRequest(action) {
    try {
        const {limit,page, search,sortBy,sortOrder} = action.payload;
        const {data} = yield call(Api.getCallRout, limit,page, search,sortBy,sortOrder);
        yield put({
            type: CALL_ROUT_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: CALL_ROUT_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleCallRoutCreateRequest(action) {
    try {
        const {limit,page, search,form} = action.payload;
        const {data} = yield call(Api.createCallRout, limit,page, search,form);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: CALL_ROUT_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: CALL_ROUT_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleCallRoutEditRequest(action) {
    try {
        const {limit,page, search,id,form} = action.payload;
        const {data} = yield call(Api.editCallRout, limit,page, search,id,form);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: CALL_ROUT_EDIT_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: CALL_ROUT_EDIT_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(CALL_ROUT_REQUEST, handleCallRoutRequest);
    yield takeLatest(CALL_ROUT_CREATE_REQUEST, handleCallRoutCreateRequest);
    yield takeLatest(CALL_ROUT_EDIT_REQUEST, handleCallRoutEditRequest);

}
