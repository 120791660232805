import React, {useRef, useState} from 'react';
import {global} from "../translate";
import {sortableKeyboardCoordinates, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SetTimer from "./SetTimer";
import {useSelector} from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {REACT_APP_API_URL} from "../config";
// const REACT_APP_API_URL = 'http://10.226.0.71:4001'

const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function IvrLine({setForm, s, fileData, handleDelete, ivrId = 0}) {
    const profile = useSelector(state => state.users.ursData)
    const [audio,setAudio]= useState('')
    const [isPlaying, setIsPlaying] = useState(0);
    const audioRef = useRef(null);
    const fetchAudio = async (src) => {
        try {
            setIsPlaying(src);
            const response = await fetch(`${REACT_APP_API_URL}/files/audio?file=${src}`);
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            setAudio(fileURL);
        } catch (error) {
            console.error('Error fetching audio file:', error);
        }
    };
    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setIsPlaying(0);
            } else {
                audioRef.current.play();
            }
        }
    };
    const {attributes, listeners, setNodeRef, transform, transition} =
        useSortable({id: s.id});
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 8,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 200,
            tolerance: 6,
        },
    });
    const [time, setTime] = useState(false);
    const [timeId, setTimeID] = useState(0);
    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleAudioEnd = () => {
        setIsPlaying(0);
    };
    return (

        <div style={{...style, width: 'fit-content', margin: '10px 0'}} ref={setNodeRef} {...sensors} {...attributes}
             className="d-flex flex-row text-center">
            <div style={{width: 150, textAlign: 'left'}}>
                <span style={{float: 'left', width: 40, display: 'inline-block'}} {...listeners}><DragIndicatorIcon
                    sx={{fontSize: 20}}/></span>
                {/*{profile.id?<span style={{width: 40, display: 'inline-block'}} ><AccessTimeIcon onClick={() => {setTime(true);setTimeID(s);console.log(s)}}  sx={{fontSize: 20, color:s.remind?color:'grey'}}/></span>:null}*/}
                <span style={{userSelect: 'text', pointerEvents: 'auto', marginLeft: 10}}>
                    {fileData.find(f => f.fname === s.num.split('*')[1] || f.fname === s.num)?.name}
                </span>
            </div>
            <div  {...listeners} style={{width: 50, margin: '0 10px'}}>
                                   <span>
                                       {s.rx}
                                   </span>
            </div>
            <div  {...listeners} style={{width: 50, margin: '0 10px'}}>
                                   <span>
                                       {s.tx}
                                   </span>
            </div>
            <div  {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
            </div>
            <div  {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeStart}
                                    </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeEnd}
                                    </span>
            </div>
            <div style={{width: 130}} className="d-flex justify-content-center">

                <span onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(s.id);
                }} className="deleteBtn" style={{background: 'red'}}>
                    {global.delete[+localStorage.getItem('atsLang') || 1]}
                </span>
            </div>
            <div className="d-flex justify-content-center">

                 <span>
                     {isPlaying === s.num?<StopCircleIcon onClick={toggleAudio}/>:<PlayCircleIcon
                         onClick={() =>
                         fetchAudio(s.num)}
                         style={{color: color}}/>}

                 </span>
            </div>
            {audio && <audio onEnded={handleAudioEnd} ref={audioRef} src={audio} className="d-none" controls autoPlay />}
            {time ? <SetTimer setForm={setForm} open={time} setOpen={setTime} ivr={timeId} ivrId={ivrId}/> : null}
        </div>
    );
}

export default IvrLine;
