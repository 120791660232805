import React, {useCallback, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {profileRequestLocal} from "../store/actions/users";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {toast} from "react-toastify";
import Api from "../Api";
import Account from "../helpers/Account";
import {lang, login} from "../translate";
import Cookies from "js-cookie";

function UrsLogin(props) {
    const [phone, setUserName] = useState('374')
    const [password, setPassword] = useState('')
    const [show, setShow] = useState(false)
    const [lang1, setLang] = useState(1)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            if (!phone || !password.trim()) {
                toast.error('Enter phone and password');
                return;
            }
            let newPhone = phone.replaceAll(' ','').replace('(','').replace(')','').replace('+','')

            const {data} = await Api.adminLogin({phone:newPhone, password, lang: lang[+localStorage.getItem('atsLang')]})
            // await dispatch(profileRequestLocal(data.user))
            Cookies.set('login','progress')
            Account.setUrsAdminToken(data)
            navigate(`/confirm/admin/${phone}`)
        } catch (e) {
            toast.error(e.response.data.message);

        }
    }, [phone, password])

    useEffect(() =>{
        Account.logoutAdmin()
    },[])
    const handleLangChange = useCallback((lang) => {
        setLang(lang)
        localStorage.setItem('atsLang',lang)
    }, [])
    const {REACT_APP_OPERATOR, REACT_APP_ATS} = process.env
    return (
        <>
            <Helmet>
                <title>{login.title[lang1]}</title>
            </Helmet>
            <div className="adminLogin">
                <div className="loginContainer" style={{borderRadius:20}}>
                    <div className="adminLoginHeader">
                        <div>
                        </div>
                        <div>
                            <span style={{cursor:'pointer'}} onClick={() => handleLangChange(3)}>
                               hy
                            </span>
                            <span>
                               |
                            </span>
                            <span style={{cursor:'pointer'}} onClick={() => handleLangChange(2)}>
                               ru
                            </span>
                            <span>
                               |
                            </span>
                            <span style={{cursor:'pointer'}} onClick={() => handleLangChange(1)}>
                               eng
                            </span>
                        </div>
                    </div>
                    <div className="adminLoginPage">

                        <form onSubmit={handleSubmit} className="adminLoginForm">

                            <div className="adminLoginMain">
                                <div
                                    className="adminInput"
                                ><input
                                    // style={{border:'none', outline:'none',}}
                                    type="text"
                                    placeholder={login.username[lang1]}
                                    value={phone}
                                    onChange={(ev) => setUserName(ev.target.value)}
                                />
                                </div>
                                <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                    <input
                                        id="adminPass"
                                        value={password}
                                        placeholder={login.pass[lang1]}
                                        type={show ? 'text' : 'password'}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />
                                    {show
                                        ? <VisibilityIcon fontSize="small" onClick={() => setShow(false)}/>
                                        : <VisibilityOffIcon fontSize="small" onClick={() => setShow(true)}/>}
                                </label>
                                <button type="submit" onClick={handleSubmit} className="adminLoginBtn">{login.title[lang1]}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default UrsLogin;
