import React, {useCallback, useEffect, useRef, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import HistoryFilter from "../components/HistoryFilter";
import {Helmet} from "react-helmet";
import {history, history1, historyMedia, menu, sip} from "../translate";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Graphics from "../components/Graphics";
import {useDispatch, useSelector} from "react-redux";
import {historyRequest} from "../store/actions/history";
import qs from "query-string";
import Api from "../Api";
import Utils from "../Utils";
import {REACT_APP_API_URL} from "../config";
import _ from 'lodash'
import HistoryFilter1 from "../components/HistoryFilter1";
import AudioPlayer from "../components/AudioPlayer";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Cookies from "js-cookie";
import IconForCall from "../components/IconForCall";
import {Tooltip} from "react-tooltip";
import HistoryLine from "../components/HistoryLine";
import Emotions from "../components/Emotions";
import {toast} from "react-toastify";

const {REACT_APP_SERVER} = process.env;
// const REACT_APP_API_URL = 'http://10.226.0.98:4001'

let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function History(props) {
    const [interacted, setInteracted] = useState(false)
    const [expanded, setExpanded] = useState(true)
    const [select, setSelect] = useState({})
    const [historyD, setHistory] = useState({})
    const [emotions, setEmotions] = useState({})
    const [loading, setLoading] = useState(0)
    const [trackIndexSet, setTrackIndex] = useState(0)
    const historyData = useSelector(state => state.history.historyData)
    const historyData1 = useSelector(state => state.history.historyData1)
    const duration = useSelector(state => state.history.duration)
    const historyStatus = useSelector(state => state.history.historyStatus)
    const count = useSelector(state => state.history.count)
    const page = useSelector(state => state.history.page)
    const profile = useSelector(state => state.users.ursData)
    const user = useSelector(state => state.users.userData)
    const bottomRef = useRef(null);
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({behavior: 'smooth'});
    };
    const profileData = useSelector(state => state.users.userData)
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSelect(data.inputNumber)
            } catch (e) {
            }
        })()
    }, []);

    const location = useLocation()
    let track = []
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [isOpen, setIsOpen] = useState('')
    let counter = +((page || 1) - 1) * (+query.limit || 10);
    const handleLimit = useCallback(async (page) => {
        query.limit = page
        Cookies.set("history", page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(historyRequest(Cookies.get("history") || query.limit || 10, query.page || 1, {
            endTime: query.endTime,
            startTime: query.startTime,
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            line: query.line || '',
            direction: query.direction || '',
            period: query.period || ''
        }))
        counter = +(query.page - 1) * +query.limit;
    }, [location.search])
    const handleAudio = useCallback((r) => {
        if (r.isAudio) {
            setInteracted(true)
            scrollToBottom();
            const index = track.findIndex(s => s.id === r.uniqueid);
            if (index !== -1) {
                setTrackIndex(index);
            } else {
                console.warn("Track not found for id:", r.uniqueid);
            }
        }
    }, [track])

    const handleText = useCallback(async (r) => {
        try {
            let isFirst = true

            if (r.isAudio || r.src) {
                if (r.isAudio || r.src) {
                    setLoading(r.uniqueid || r.id);
                    let processing = true;

                    while (processing) {
                        const {data} = await Api.getEmotions(r.uniqueid || r.id);

                        if (!data.emotion.status || data.emotion.status !== "processing") {
                            setEmotions(data);
                            setLoading(0);
                            processing = false; // Stop polling
                        } else {
                            if (isFirst) {
                                toast.info("Processing... Please wait a few minutes.");
                                isFirst = false
                            }
                            await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds before retrying
                        }
                    }
                }
            }
        } catch (e) {
            setLoading(0)
            toast.error('Error accured ' + e.response.data.message)
        }
    }, [])
    const handleChangePage = useCallback(async (page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        counter = +(page - 1) * +query.limit;
        await dispatch(historyRequest(Cookies.get("history") || query.limit || 10, query.page || 1, {
            endTime: query.endTime,
            startTime: query.startTime,
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            line: query.line || '',
            direction: query.direction || '',
            period: query.period || ''
        }))
    }, [location.search])
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            let yourDate = new Date()
            counter = +(query.page - 1) * +query.limit;
            const offset = yourDate.getTimezoneOffset()
            yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
            await dispatch(historyRequest(Cookies.get("history") || query.limit || 10, query.page || 1, {
                endTime: query.endTime || yourDate.toISOString().split('T')[0],
                startTime: query.startTime || yourDate.toISOString().split('T')[0],
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                line: query.line || '',
                direction: query.direction || '',
                period: query.period || ''
            }))
        })()
    }, []);


    useEffect(() => {
        if (REACT_APP_SERVER === 'Ucom') {
            historyData1?.response?.docs.map(row => {
                if (row.items.length === 1) {
                    if (row.items[0].extension.split("-").length === 2 && (row.items[0].extension.split("-")[0] || row.items[0].extension.split("-")[1])) {
                        if (row.items[0].extension.split("-")[0].length === 7) {
                            row.items[0].ext_note = select?.sip?.find(s => s.number === row.items[0].extension.split("-")[0])?.context
                        }
                        if (row.items[0].extension.split("-")[1].length === 7) {
                            row.items[0].ext_note = row.items[0].ext_note + " - " + select?.sip?.find(s => s.number === row.items[0].extension.split("-")[1])?.context
                        }
                    }
                    if (row.items[0].extension.replace('-', '').length === 9 || row.items[0].extension.replace('-', '').length === 8) {
                        row.items[0].ext_note = select?.allowed?.find(s => s.number === row.items[0].extension.replace('-', ''))?.context
                        if (row.items[0].extension.replace('-', '').length === 9) {
                            row.items[0].extension = "374" + row.items[0].extension.replace('-', '').slice(1)
                        } else {
                            row.items[0].extension = "374" + row.items[0].extension.replace('-', '')
                        }
                    }

                    if (row.items[0].destination.split("-").length === 2 && row.items[0].destination.split("-")[1].length === 8) {
                        row.items[0].dest_note = select?.allowed?.find(s => s.number === row.items[0].destination.split("-")[1])?.context
                        row.items[0].destination = "0" + row.items[0].destination.split("-")[1]
                    } else if (row.items[0].destination.split("-").length === 2 && (!row.items[0].destination.split("-")[1].length || !row.items[0].destination.split("-")[0].length)) {
                        row.items[0].dest_note = select?.allowed?.find(s => s.number === row.items[0].destination.replace('-', ''))?.context
                        row.items[0].destination = row.items[0].destination.replace('-', '')
                    } else if (row.items[0].destination.split("-").length === 2 && row.items[0].destination.split("-")[0].length === 7) {
                        row.items[0].dest_note = select?.sip?.find(s => s.number === row.items[0].destination.split("-")[0])?.context

                    }
                } else {
                    row.items.map(r => {

                            if (r.extension.split("-").length === 2 && (r.extension.split("-")[0] || r.extension.split("-")[1])) {
                                if (r.extension.split("-")[0].length === 7) {
                                    r.ext_note = select?.sip?.find(s => s.number === r.extension.split("-")[0])?.context
                                }
                                if (r.extension.split("-")[1].length === 7) {
                                    r.ext_note = r.ext_note + " - " + select?.sip?.find(s => s.number === r.extension.split("-")[1])?.context
                                }
                            }
                            if (r.extension.replace('-', '').length === 9 || r.extension.replace('-', '').length === 8) {
                                r.ext_note = select?.allowed?.find(s => s.number === r.extension.replace('-', ''))?.context
                                if (r.extension.replace('-', '').length === 9) {
                                    r.extension = "374" + r.extension.replace('-', '').slice(1)
                                } else {
                                    r.extension = "374" + r.extension.replace('-', '')
                                }
                            }

                            if (r.destination.split("-").length === 2 && r.destination.split("-")[1].length === 8) {
                                r.dest_note = select?.allowed?.find(s => s.number === r.destination.split("-")[1])?.context
                                r.destination = "0" + r.destination.split("-")[1]
                            } else if (r.destination.split("-").length === 2 && (!r.destination.split("-")[1].length || !r.destination.split("-")[0].length)) {
                                r.dest_note = select?.allowed?.find(s => s.number === r.destination.replace('-', ''))?.context
                                r.destination = r.destination.replace('-', '')
                            } else if (r.destination.split("-").length === 2 && r.destination.split("-")[0].length === 7) {
                                r.dest_note = select?.sip?.find(s => s.number === r.destination.split("-")[0])?.context

                            }
                        }
                    )
                }
                return row;
            })

        }

        setHistory(historyData1)
    }, [historyData1, select, REACT_APP_SERVER]);
    // useEffect(() => {
    //     const eventSource = new EventSource(`${REACT_APP_API_URL}/history/emotion_endpoint`);
    //     eventSource.onmessage = async (event) => {
    //         if(user.id === JSON.parse(event.data).emotion.member){
    //             toast.success('Processing finished successfully')
    //             setLoading(0)
    //             await handleText({src:JSON.parse(event.data).emotion.record_id,id:JSON.parse(event.data).emotion.record_id})
    //         }
    //     };
    //
    //     return () => eventSource.close();
    // }, [user,REACT_APP_API_URL]);

    return (
        <>
            <Helmet>
                <title>
                    {menu.history[+localStorage.getItem('atsLang') || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent m-0">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <Accordion expanded={expanded}>
                            <AccordionSummary
                                style={{
                                    padding: '0 30px',
                                    height: 80,
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                                aria-expanded="true"
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="w-100"
                                     style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <h1 style={{fontSize: 20, margin: 0}}>
                                            <Link
                                                to={`/history?${qs.stringify(query, {arrayFormat: "comma"})}`}>{menu.history[+localStorage.getItem('atsLang') || 1]}</Link>
                                        </h1>
                                        <div className="header_border" style={{height: 40, marginBottom: 15}}/>
                                        <h1 style={{fontSize: 20, margin: 0}}>
                                            <Link to={`/graphic?${qs.stringify(query, {arrayFormat: "comma"})}`}
                                                  onClick={() => {
                                                      navigate('/graphic');
                                                      window.location.reload()
                                                  }}>Graphic</Link>
                                        </h1>
                                    </div>

                                    <span style={{display: 'flex', alignItems: 'center'}}>
                                    <CalendarMonthIcon style={{transition: 'transform 0s'}}
                                                       onClick={() => setExpanded(!expanded)}/>
                                </span>
                                </div>
                            </AccordionSummary>
                            <hr className="m-0"/>

                            <AccordionDetails>
                                {REACT_APP_SERVER === 'Beeline' ? <HistoryFilter1/> : <HistoryFilter/>}
                            </AccordionDetails>
                        </Accordion>
                        <hr style={{borderColor: '#d4d4d4', margin: 0}}/>
                        {window.location.pathname === '/graphic' ?
                            <Graphics/>
                            :
                            <TableContainer>
                                <Table stickyHeader aria-label="table">
                                    <TableHead>
                                        <TableRow className="sip_header">
                                            {history.map((column, i) => (
                                                <TableCell
                                                    style={i === 0 || i === history.length - 1 ? {padding: '5px 13px'} : {padding: 5}}
                                                    key={i}
                                                    align="center"
                                                >
                                                    {column[+localStorage.getItem('atsLang') || 1]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="accordion" id="accordionExample"
                                               style={_.isEmpty(historyD?.response?.docs) ? {height: 'calc(100vh - 600px)'} : {}}>
                                        {!_.isEmpty(historyD?.response?.docs) ? historyD?.response?.docs?.map((row, _) => {
                                            if (row.items.length === 1) {
                                                if (row.items[0].isAudio && (!Cookies.get('viewer') || profile.isLimited)) {
                                                    track.push({
                                                        src: REACT_APP_API_URL + '/history/audio?file=' + row.items[0].uniqueid + '&key=akNwNXN1U2k4bzdEQ09QU29KMUpYbXFiQTV5c3FmUm1MOUNheW5qWlYwOD0=',
                                                        id: row.items[0].uniqueid,
                                                        name: row.items[0]?.start + '_' + row.items[0]?.extension + '_' + row.items[0]?.destination,
                                                        type: (row.items[0].line && row.items[0].line !== 'null') || row.items[0].ext_num ? 'Outgoing' : row.items[0].in_num ? 'Incoming' : ''
                                                    })
                                                }
                                                counter++
                                                if (REACT_APP_SERVER === 'Ucom') {

                                                }
                                                return (
                                                    <HistoryLine loading={loading} setIsOpen={setIsOpen} isOpen={isOpen}
                                                                 key={row.items[0].id} counter={counter}
                                                                 handleAudio={handleAudio} handleText={handleText}
                                                                 r={row.items[0]}/>

                                                );

                                            } else {
                                                return row.items.map((r, ind) => {
                                                    if (r.isAudio && (!Cookies.get('viewer') || profile.isLimited)) {
                                                        track.push({
                                                            src: REACT_APP_API_URL + '/history/audio?file=' + r.uniqueid + '&key=akNwNXN1U2k4bzdEQ09QU29KMUpYbXFiQTV5c3FmUm1MOUNheW5qWlYwOD0=',
                                                            id: r.uniqueid,
                                                            name: r?.start + '_' + r?.extension + '_' + r?.destination,
                                                            type: (r.line && r.line !== 'null') || r.ext_num ? 'Outgoing' : r.in_num ? 'Incoming' : ''
                                                        })
                                                    }
                                                    counter++;

                                                    if (ind === 0) {
                                                        return (

                                                            <HistoryLine loading={loading} setIsOpen={setIsOpen}
                                                                         isOpen={isOpen} background={"#e5e5e5"}
                                                                         key={r.id} counter={counter}
                                                                         handleAudio={handleAudio}
                                                                         handleText={handleText} r={r}/>

                                                        );
                                                    } else {
                                                        return (
                                                            <TableRow
                                                                style={isOpen !== r.linkedid ? {display: 'none',} : {
                                                                    background: '#efefef',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className="sip_num" hover role="checkbox" tabIndex={-1}
                                                                key={r.id}>

                                                                <TableCell
                                                                    align="center">
                                                                    {REACT_APP_SERVER === 'Ucom' ? '' : counter}
                                                                    {REACT_APP_SERVER === 'Ucom' ? r.disposition !== "ANSWERED" ?
                                                                        <IconForCall color={history1.callType[1].color}
                                                                                     path={history1.callType[1].path}/> : r.ext_num ?
                                                                            <IconForCall
                                                                                color={history1.callType[2].color}
                                                                                path={history1.callType[2].path}/> :
                                                                            <IconForCall
                                                                                color={history1.callType[0].color}
                                                                                path={history1.callType[0].path}/> : null}

                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%', fontSize: 10}}
                                                                    align="center">
                                                                    {Utils.newFormat(r.start)} - {Utils.newFormat(r.endz)}
                                                                </TableCell>
                                                                <TableCell
                                                                    data-tooltip-content={r.ext_note || ''}
                                                                    data-tooltip-id={r.id}
                                                                    style={{width: 100 / 8 + '%'}} align="center">
                                                                    <Tooltip className={"tooltip"} id={r.id}/>

                                                                    {r.extension ? r.extension : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}} align="center">
                                                                    {Utils.secToHours(r.duration ? r.duration : 0)}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}}
                                                                    align="center">
                                                                    {r.line && r.line !== 'null' ? r.line : r.ext_num ? r.ext_num[0] : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}}
                                                                    align="center">
                                                                    {r.in_num ? r.in_num : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}}
                                                                    align="center">
                                                                    {r.virtual ? r.virtual : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}}
                                                                    align="center">
                                                                    {r.disposition ? r.disposition : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    data-tooltip-content={r.dest_note || ''}
                                                                    data-tooltip-id={r.id}
                                                                    style={{width: 100 / 8 + '%'}}
                                                                    align="center">
                                                                    {r.destination ? r.destination : ''}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{width: 100 / 8 + '%'}} align="center">
                                                                    {r.rate ? r.rate :
                                                                        <DoNotDisturbIcon style={{color: '#ccc'}}/>}

                                                                </TableCell>
                                                                <TableCell align="center"
                                                                           style={{
                                                                               width: 100 / 8 + '%',
                                                                               cursor: 'pointer'
                                                                           }}>
                                                                    {Cookies.get('viewer') && !profile.isLimited ?
                                                                        <PlayCircleIcon style={{color: '#ccc'}}/> :
                                                                        <PlayCircleIcon onClick={() =>
                                                                            handleAudio(r)}
                                                                                        style={!r.isAudio ? {color: '#ccc'} : {color: color}}/>
                                                                    }
                                                                    {Cookies.get('viewer') && !profile.isLimited ?
                                                                        <ChatBubbleIcon style={{color: '#ccc'}}/> :
                                                                        <ChatBubbleIcon onClick={() =>
                                                                            handleText(r)}
                                                                                        style={!r.isAudio ? {color: '#ccc'} : {color: color}}/>
                                                                    }
                                                                </TableCell>

                                                            </TableRow>
                                                        );
                                                    }
                                                })
                                            }

                                        }) : <TableRow className="sip_num" role="checkbox" tabIndex={-1}>
                                            <TableCell colSpan={11} align="center">
                                                {historyStatus === "request" ? 'Loading...' : 'No data'}
                                            </TableCell>
                                        </TableRow>}
                                        <TableRow className="sip_num" role="checkbox" tabIndex={-1}>
                                            <TableCell className="p-0" colSpan={2} align="right">
                                                Total
                                            </TableCell>
                                            <TableCell className="p-0" colSpan={4} align="center">
                                                {Utils.secToHours(duration ? duration : 0)}
                                            </TableCell>
                                            <TableCell className="p-0" colSpan={2} align="left">
                                                Total call count
                                            </TableCell>
                                            <TableCell className="p-0" colSpan={5} align="center">
                                                {count || 0}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        }
                        {window.location.pathname === '/graphic' ? null :
                            <div className="foot_footer d-flex align-items-center justify-content-end">
                                <a href={REACT_APP_API_URL + `/history/exel?page=${query.page || 1}&userId=${profileData.id}&disposition=${query.disposition || ''}&line=${query.line || ''}&status=${query.status || ''}&endTime=${query.endTime || new Date().toISOString()}&startTime=${query.startTime || new Date().toISOString()}&number=${query.number || ''}&period=${query.period || ''}`}>Exel</a>

                                <TablePagination
                                    className="hello"
                                    rowsPerPageOptions={[10, 25, 100, {label: 'All', value: -1}]}
                                    component="div"
                                    count={count || 1}
                                    rowsPerPage={Cookies.get("history") || query.limit || 10}
                                    page={page - 1 || 0}
                                    onPageChange={async (event, newPage) => await handleChangePage(newPage)}
                                    onRowsPerPageChange={async (ev, newPage) => await handleLimit(newPage.props.value)}
                                />
                            </div>}

                    </Paper>
                </div>
                <div ref={bottomRef}>
                    {track.length ?
                        <AudioPlayer loading={loading} handleText={handleText} interacted={interacted} track={track}
                                     trackIndexSet={trackIndexSet}
                                     setTrackIndex={setTrackIndex}/> : null}
                </div>

                {!_.isEmpty(emotions) ? <Emotions open={emotions} setOpen={setEmotions}/> : null}
            </Wrapper>

        </>
    );
}

// SOLR_URL +

export default History;
