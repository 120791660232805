import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {included, global, input, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {include, includeEditRequest, includeRequest, providerRequest} from "../store/actions/included";
import Api from "../Api";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight:'97vh'
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
function EditInclude({open, setOpen}) {
    let [provider,setProvider] = useState('')
    const [form, setForm] = useState({
        id:0,
        note: '',
        phone: '',
        group: '',
        payed:0,
        mob:0,
        inter:0,
        fixed:0,
        out:'',
    })

    const [out,setOut] = useState([]);
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleSave = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(includeEditRequest(Cookies.get("included") ||query.limit||10,query.page,query.search,form,query.sortBy,query.sortOrder))
        setOpen(0)
    }, [open,form,location.search])

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const {data} = await Api.allowedSingle(open)
                setForm({
                    id:open,
                    note:data.single.context,
                    phone:data.single.phone,
                    group:data.single.pickupgroup.split('_')[1],
                    out:data.single.sipdid,
                    mob: data?.single?.codetypeResult?.find(c => c.id === 1) ? 1 : 0,
                    fixed: data?.single?.codetypeResult?.find(c => c.id === 2) ? 2 : 0,
                    inter: data?.single?.codetypeResult?.find(c => c.id === 3) ? 3 : 0,
                    payed: data?.single?.codetypeResult?.find(c => c.id === 4) ? 4 : 0,
                })
                setProvider(data.single.provider)
                setOut(data.fixNum)
                setLoading(false)
            }catch (e) {

            }
        })()
    },[open])
    if(loading){
        return null
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(0)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {included.private[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>

                    <form onSubmit={handleSave}>
                        <div className="row">
                            <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                <span>{included.phone[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input readOnly={!Cookies.get('viewer') || !Cookies.get('original')} value={form.phone} onChange={(ev) => handleChange('phone', ev.target.value)}/>
                            </label>
                            <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                <span>{included.group[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input
                                    type="number"
                                    value={form.group}
                                    onChange={(ev) => handleChange('group', ev.target.value)}/>
                            </label>
                            {+provider === 1?null:<label className="col-md-12 count" style={{margin: '20px 0'}}>
                                <span>{sip.note[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input value={form.note}
                                       onChange={(ev) => handleChange('note', ev.target.value)}/>
                            </label>}
                            {+provider === 1?null:<label className="col-md-6 count" style={{margin: '20px 0'}}>
                                <span>{sip.out[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <Select
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    onChange={(ev) => handleChange('out', ev.value)}
                                    menuPortalTarget={document.body}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    defaultValue={form.out}
                                    name="color"
                                    options={out.map(k => (
                                        {value: k.name, label: `${k.name}`}
                                    ))}
                                />
                            </label>}
                            {+provider === 1?null:<div className="col-md-6 count" style={{margin: '20px 0'}}>
                                <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.mobile[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                    <input checked={form.mob}
                                           onChange={(ev) => handleChange('mob', ev.target.checked ? 1 : 0)}
                                           style={{width: 50}} type="checkbox"/>
                                </p>
                                <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.fix_num[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                    <input checked={form.fixed}
                                           onChange={(ev) => handleChange('fixed', ev.target.checked ? 2 : 0)}
                                           style={{width: 50}} type="checkbox"/>
                                </p>
                                <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.international[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                    <input checked={form.inter}
                                           onChange={(ev) => handleChange('inter', ev.target.checked ? 3 : 0)}
                                           style={{width: 50}} type="checkbox"/>
                                </p>
                                <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.payed[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                    <input checked={form.payed}
                                           onChange={(ev) => handleChange('payed', ev.target.checked ? 4 : 0)}
                                           style={{width: 50}} type="checkbox"/>
                                </p>

                            </div>}
                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleSave} className="addBtnBig">
                                {global.edit[+localStorage.getItem('atsLang')  || 1 ]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditInclude;
