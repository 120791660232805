import React from 'react';

function Smile({color = "#222222", icon, size = 40}) {
    switch (icon) {
        case 'angry':
            return (
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="9.5" stroke={color} stroke-linecap="round"/>
                    <path
                        d="M8.20857 16.622C8.63044 16.2567 9.20751 15.9763 9.86133 15.7876C10.5191 15.5977 11.256 15.5 12 15.5C12.744 15.5 13.4809 15.5977 14.1387 15.7876C14.7925 15.9763 15.3696 16.2567 15.7914 16.622"
                        stroke={color} stroke-linecap="round"/>
                    <path d="M17 8L14 10" stroke={color} stroke-linecap="round"/>
                    <path d="M7 8L10 10" stroke={color} stroke-linecap="round"/>
                    <circle cx="8" cy="10" r="1" fill={color}/>
                    <circle cx="16" cy="10" r="1" fill={color}/>
                </svg>
            );
        case 'disgust':
            return (
                <svg fill="#ffffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     width={size} height={size}
                     viewBox="-10.4 -10.4 280.80 280.80"  stroke="#ffffff">

<g id="SVGRepo_bgCarrier" stroke-width="0">

<rect x="-10.4" y="-10.4" width="280.80" height="280.80" rx="140.4" fill={color} strokeWidth="0"/>

</g>

                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

                    <g id="SVGRepo_iconCarrier"> <path
                        d="M130,2C59.3,2,2,59.3,2,130s57.3,128,128,128s128-57.3,128-128S200.7,2,130,2z M71.9,133.7c-13,0-23.5-10.5-23.5-23.5 c0-2.4,0.4-4.6,1-6.8L34,102.2l1.1-14l63.4,4.9l11.8-20.4l12.1,7l-16.2,28l-11-0.8c0.2,1.1,0.2,2.2,0.2,3.3 C95.4,123.1,84.9,133.7,71.9,133.7z M190.8,196c0,0-10.3-6-37.5-2.6c-21.8,2.6-57.9,15.8-57.9,15.8s23.3-42,61.3-45.7 C194.1,159.7,190.8,196,190.8,196z M211.6,110.2c0,6.9-2.9,13-7.6,17.3c-7.6-1.7-17.2-2.3-29.4-1.4c-1.1,0.1-2.2,0.2-3.4,0.3 c-4.1-4.2-6.6-10-6.6-16.3c0-1.1,0.1-2.2,0.2-3.3l-11,0.8l-16.2-28l12.1-7l11.8,20.4l63.4-4.9l1.1,14l-15.4,1.2 C211.3,105.5,211.6,107.8,211.6,110.2z"/> </g>

</svg>
            );
        case 'happy':
            return (

                <svg
                    fill={color}
                    height={size}
                    width={size}
                    viewBox="0 0 295.996 295.996"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path d="M147.998,0C66.392,0,0,66.392,0,147.998c0,81.606,66.392,147.998,147.998,147.998c81.606,0,147.998-66.392,147.998-147.998
                C295.996,66.392,229.605,0,147.998,0z M147.998,279.996c-36.257,0-69.143-14.696-93.023-38.44
                c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.847,16,170.047,16,147.998C16,75.214,75.214,16,147.998,16
                c34.523,0,65.987,13.328,89.533,35.102c12.208,11.288,22.289,24.844,29.558,39.996c8.27,17.239,12.907,36.538,12.907,56.9
                C279.996,220.782,220.782,279.996,147.998,279.996z"/>
                        <path d="M97.41,114.4c8.6,0,15.597,6.597,15.597,15.597h16c0-18-14.174-31.597-31.597-31.597
                c-17.423,0-31.597,13.597-31.597,31.597h16C81.813,120.997,88.811,114.4,97.41,114.4z"/>
                        <path d="M198.584,114.4c8.6,0,15.597,6.597,15.597,15.597h16c0-18-14.174-31.597-31.597-31.597
                c-17.423,0-31.597,13.597-31.597,31.597h16C182.987,120.997,189.984,114.4,198.584,114.4z"/>
                        <path d="M147.715,229.995c30.954,0,60.619-15.83,77.604-42.113l-13.439-8.684c-15.596,24.135-44.134,37.605-72.693,34.308
                c-22.262-2.567-42.849-15.393-55.072-34.308l-13.438,8.684c14.79,22.889,39.716,38.409,66.676,41.518
                C140.814,229.8,144.27,229.995,147.715,229.995z"/>
                    </g>
                </svg>
            );

        case 'sad':
            return (
                <svg
                    fill={color}
                    width={size}
                    height={size}
                    viewBox="0 0 295.996 295.996"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path d="M147.998,0C66.392,0,0,66.392,0,147.998c0,81.606,66.392,147.998,147.998,147.998c81.606,0,147.998-66.392,147.998-147.998
                C295.996,66.392,229.605,0,147.998,0z M147.998,279.996c-36.257,0-69.143-14.696-93.023-38.44
                c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.847,16,170.047,16,147.998C16,75.214,75.214,16,147.998,16
                c34.523,0,65.987,13.328,89.533,35.102c12.208,11.288,22.289,24.844,29.558,39.996c8.27,17.239,12.907,36.538,12.907,56.9
                C279.996,220.782,220.782,279.996,147.998,279.996z"/>
                        <circle cx="98.497" cy="115.998" r="16"/>
                        <circle cx="197.497" cy="115.998" r="16"/>
                        <rect x="179.451" y="64.405" transform="matrix(0.9176 0.3974 -0.3974 0.9176 45.8265 -76.3235)"
                              width="55.22" height="16"/>
                        <rect x="61.324" y="64.405" transform="matrix(-0.9176 0.3974 -0.3974 -0.9176 199.317 103.5043)"
                              width="55.219" height="16"/>
                        <path d="M214.247,202.783c1.228,2.176,2.358,4.438,3.363,6.724l14.648-6.436c-1.218-2.771-2.589-5.513-4.075-8.148
                c-17.022-30.189-50.407-48.542-85.014-46.744c-34.383,1.779-65.563,23.325-79.435,54.892l14.648,6.438
                c11.461-26.08,37.215-43.881,65.613-45.351C172.581,162.675,200.181,177.837,214.247,202.783z"/>
                    </g>
                </svg>
            );
        case 'neutral':
            return (
                <svg fill={color} width={size} height={size} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M7.5,15 C7.22385763,15 7,14.7761424 7,14.5 C7,14.2238576 7.22385763,14 7.5,14 L16.5,14 C16.7761424,14 17,14.2238576 17,14.5 C17,14.7761424 16.7761424,15 16.5,15 L7.5,15 Z M9,11 C8.44771525,11 8,10.5522847 8,10 C8,9.44771525 8.44771525,9 9,9 C9.55228475,9 10,9.44771525 10,10 C10,10.5522847 9.55228475,11 9,11 Z M15,11 C14.4477153,11 14,10.5522847 14,10 C14,9.44771525 14.4477153,9 15,9 C15.5522847,9 16,9.44771525 16,10 C16,10.5522847 15.5522847,11 15,11 Z"/>
                </svg>
            );
        case 'surprised':
            return (
                <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"
                     stroke={color} stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="8" y1="9" x2="8.01" y2="9" stroke-width="2" stroke-linecap="round"></line>
                    <line x1="15.99" y1="9" x2="16" y2="9" stroke-width="2" stroke-linecap="round"></line>
                    <circle cx="12" cy="15" r="3"></circle>
                </svg>
            );
        case 'scared':
            return (
                <svg fill={color} width={size} height={size} viewBox="0 0 295.996 295.996"
                     xmlns="http://www.w3.org/2000/svg"
                     preserveAspectRatio="xMidYMid meet">

                    <g>
                        <path d="M147.998,0C66.392,0,0,66.392,0,147.998c0,81.606,66.392,147.998,147.998,147.998
        c81.606,0,147.998-66.392,147.998-147.998C295.996,66.392,229.604,0,147.998,0z M147.998,279.996
        c-36.257,0-69.143-14.696-93.023-38.44c-9.536-9.482-17.631-20.41-23.934-32.42C21.442,190.847,16,170.048,16,147.998
        C16,75.214,75.214,16,147.998,16c34.523,0,65.987,13.328,89.533,35.102c12.208,11.288,22.289,24.844,29.558,39.996
        c8.27,17.239,12.907,36.538,12.907,56.9C279.996,220.782,220.782,279.996,147.998,279.996z"/>

                        <path d="M147.992,196.997c7.237,0,10.414,2.789,15.223,7.012c5.576,4.896,12.516,10.988,25.779,10.988
        c13.265,0,20.038-6.093,25.614-10.988c4.809-4.223,8.056-7.012,15.056-7.012v-16c-13,0-20.037,6.093-25.613,10.988
        c-4.809,4.223-7.903,7.012-15.14,7.012c-7.237,0-10.372-2.789-15.181-7.012c-5.576-4.896-12.495-10.988-25.758-10.988
        c-13.263,0-20.191,6.093-25.766,10.988c-4.809,4.223-7.98,7.012-15.216,7.012c-7.235,0-10.576-2.789-15.385-7.012
        c-5.575-4.896-11.941-10.988-25.941-10.988v16c8,0,10.579,2.789,15.387,7.012c5.575,4.896,12.597,10.988,25.861,10.988
        c13.264,0,20.244-6.093,25.819-10.989C137.539,199.786,140.757,196.997,147.992,196.997z"/>

                        <polygon
                            points="106.269,147.953 135.522,118.698 106.268,89.445 94.955,100.76 112.896,118.698 94.954,136.639 "/>
                        <polygon
                            points="199.374,136.639 181.433,118.698 199.373,100.76 188.061,89.445 158.806,118.698 188.06,147.953 "/>
                    </g>

                </svg>
            );

    }

}

export default Smile;