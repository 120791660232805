import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {did, global, input, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AddAndEditSip from "../components/AddAndEditSip";
import {sipProviderLocalDelete, sipProviderRequest} from "../store/actions/sip";
import Api from "../Api";
import Utils from "../Utils";
import qs from "query-string";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SingleSipProvider from "../components/SingleSipProvider";
import TablePagination from "@mui/material/TablePagination";
import Cookies from "js-cookie";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'

function SipProvider(props) {
    const [copied, setCopied] = useState(false)
    const [single, setSingle] = useState(0)
    const sipProviderData = useSelector(state => state.sip.sipProvider)
    const sipProviderStatus = useSelector(state => state.sip.sipProviderStatus)
    const counts = useSelector(state => state.sip.totalS)
    const page = useSelector(state => state.sip.pageS)
    const [edit, setEdit] = useState(0)
    const [create, setCreate] = useState(false)
    const [count, setCount] = useState(0)
    const [count1, setCount1] = useState(0)
    const dispatch = useDispatch()
    const location = useLocation()
    const [isSearch, setIsSearch] = useState(false)
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to delete?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите судалить?" :
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(sipProviderLocalDelete(id))
                await Api.deleteProvider(id)
            }
        } catch (e) {
            console.log(e)
        }
    }, [])
    useEffect(() => {
        (async () => {
            await dispatch(sipProviderRequest(query.search,Cookies.get("provider") ||query.limit || 10,query.page || 1))
        })()

    }, [location.search])
    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("provider",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    useEffect(() => {
        if (sipProviderData) {
            setCount(Utils.getCount(sipProviderData, 'count'))
            setCount1(Utils.getCount(sipProviderData, 'lines'))
        }
    }, [sipProviderData])
    return (
        <>
            <Helmet>
                <title>
                    {did.sip[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={5}>
                                            {did.sip[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15, padding: '0 35px',}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end align-items-center">
                                                <label className="search">
                                                    <input
                                                        value={query.search}
                                                        onChange={handleSearch}
                                                        placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                                </span>
                                                {!Cookies.get('viewer') || !Cookies.get('original')?null:<span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.forward[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.note[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.active[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.date[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.line[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.no_line[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!sipProviderData.length && sipProviderStatus === 'request' ?

                                        <TableRow hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                                Loading...
                                            </TableCell>
                                        </TableRow> :
                                        !sipProviderData.length && sipProviderStatus === 'ok' ?
                                            <TableRow hover role="checkbox" tabIndex={-1}>

                                                <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                                    no data

                                                </TableCell>
                                            </TableRow> : sipProviderData.map(s => (
                                                <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>
                                                    <TableCell align="center">
                                                        {+s.isactive === 2 ?
                                                            <CheckCircleIcon style={{color: color}}/> : null}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.username}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.redirect?.output}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {s.notes}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {s.in} - {s.out}

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.inTime.intime} - {s.outTime.outtime}
                                                        {/*{s.inTimeT ? Utils.secToHours(s.inTimeT) : ''} - {s.outTimeT ? Utils.secToHours(s.outTimeT) : ''}*/}

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.lines}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{color: 'red'}}>
                                                        {+s.nodline || ''}

                                                    </TableCell>

                                                    <TableCell align="center" className="action">
                                                        <p style={{display: "flex", justifyContent: 'center'}}>
                                                            <span onClick={() => setSingle(s.id)}
                                                                  className="edit">
                                                                <FontAwesomeIcon
                                                                    icon={faEye}/>
                                                            </span>
                                                            <span onClick={() => setEdit(s.id)}
                                                                  className="edit"><FontAwesomeIcon
                                                                icon={faPenToSquare}/></span>
                                                            <span onClick={() => handleDelete(s.id)}
                                                                  className="delete"><FontAwesomeIcon
                                                                icon={faTrashCan}/></span>
                                                        </p>
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                    }

                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell/>

                                        <TableCell colSpan={5}>
                                            {global.count[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell align="center" sx={{fontSize: 13}}>
                                            {count1}
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                sip.phone[+localStorage.getItem('atsLang')  || 1],
                                sip.note[+localStorage.getItem('atsLang')  || 1],
                                did.active[+localStorage.getItem('atsLang')  || 1],
                                did.date[+localStorage.getItem('atsLang')  || 1],
                                did.no_line[+localStorage.getItem('atsLang')  || 1],
                            ], sipProviderData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                sip.phone[+localStorage.getItem('atsLang')  || 1],
                                sip.note[+localStorage.getItem('atsLang')  || 1],
                                did.active[+localStorage.getItem('atsLang')  || 1],
                                did.date[+localStorage.getItem('atsLang')  || 1],
                                did.no_line[+localStorage.getItem('atsLang')  || 1],
                            ], sipProviderData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={+counts}
                                rowsPerPage={Cookies.get("provider") ||query.limit || 10}
                                page={+page - 1}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev, newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>
                        {copied ? <div className="copied">Copied</div> : null}
                    </Paper>
                </div>
                {single ? <SingleSipProvider open={single} edit={single} setOpen={setSingle}/> : null}
                {create ? <AddAndEditSip open={create} edit={false} setOpen={setCreate}/> : null}
                {edit ? <AddAndEditSip open={edit} edit={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default SipProvider;
