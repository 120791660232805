import React, {useCallback, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import {amo, bitrix, global, input} from "../translate";
import Api from "../Api";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {REACT_APP_API_URL} from '../config';
const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    overflow: 'hidden'
};
const REDIRECT_URI = REACT_APP_SERVER === 'ATS'?'https://account.ats.am':REACT_APP_API_URL;

function CallCenterEnter({open, setOpen}) {
    const [domain, setDomain] = useState('')
    const [key, setKey] = useState('')
    const [webhook, setWebhook] = useState('')
    const navigate = useNavigate()
    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        try {
            await Api.callCenterCreate({domain,key,webhook})
            toast.success('Success')
            navigate('/crm_integration/callcenter')

        } catch (e) {

        }
    }, [domain,key,webhook])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>

                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between">
                            <h3>{input.callcenter[+localStorage.getItem('atsLang')  || 1]}</h3>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen('')
                                navigate('/crm_integration')
                            }}>
                            x
                            </span>
                        </div>
                    </form>
                    <div id="transition-modal-description sip_content" className="test_mode">
                        <label className="count" style={{width: '93%',marginBottom:30}}>
                            <span>Command</span>
                            <input value={domain} onChange={(ev) => setDomain(ev.target.value)}/>
                        </label>
                        <label className="count" style={{width: '93%',marginBottom:30}}>
                            <span>Webhook</span>
                            <input value={webhook} onChange={(ev) => setWebhook(ev.target.value)}/>
                        </label>
                        <label className="count" style={{width: '93%',marginBottom:30}}>
                            <span>KEY</span>
                            <input value={key} onChange={(ev) => setKey(ev.target.value)}/>
                        </label>
                        <div className="d-flex justify-content-center">
                            <button style={{padding: '6px 17px'}} className="addBtn" onClick={handleSubmit}>
                                {global.edit[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default CallCenterEnter;
